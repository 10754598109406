import type { AuthContextType } from '@context/auth.context';
import useAuth from '@hooks/use-auth';
import { apiRequest } from '@services/fetcher';
import { useEffect, useState } from 'react';

export const downloadToLocalObject = async (auth: AuthContextType, url: string) => {
  const response = await apiRequest<Response>(url, auth, {
    plain: true,
  });

  if (response instanceof Error) {
    throw response;
  }

  const buffer = await response.arrayBuffer();
  return URL.createObjectURL(new Blob([buffer], { type: response.headers.get('Content-Type') || undefined }));
};

const useMedia = (messageId: number, trigger = true, mediaId?: string) => {
  const auth = useAuth();
  let url = `${import.meta.env.VITE_API_URL}/message/${messageId}/media`;
  if (mediaId) {
    url = `${url}/${mediaId}`;
  }
  const [media, setMedia] = useState<string>();

  useEffect(() => {
    let mediaUrl: string;
    if (trigger) {
      downloadToLocalObject(auth, url).then((objectUrl) => {
        mediaUrl = objectUrl;
        setMedia(mediaUrl);
      });
    }

    return () => {
      mediaUrl && URL.revokeObjectURL(mediaUrl);
    };
  }, [auth, trigger, url]);

  return media;
};

export default useMedia;
