import type { ApiDocument } from '@interfaces/messages';

export const FILE_EXTENSION_REGEX = /\.[^/.]+$/;
const ONE_GIGABYTE = 1024 * 1024 * 1024;
const ONE_MEGABYTE = 1024 * 1024;

export function getFileSizeString(bytes: number) {
  if (bytes > ONE_GIGABYTE / 2) {
    return `${(bytes / ONE_GIGABYTE).toFixed(1).replace(/\.0$/, '')} GB`;
  }
  if (bytes > ONE_MEGABYTE / 2) {
    return `${(bytes / ONE_MEGABYTE).toFixed(1).replace(/\.0$/, '')} MB`;
  }
  return `${(bytes / 1024).toFixed(1).replace(/\.0$/, '')} KB`;
}

export function getDocumentExtension(document: ApiDocument) {
  const { file_name, mime_type } = document;

  return getFileExtension(file_name, mime_type);
}

export function getFileExtension(fileName?: string, mimeType?: string) {
  if (fileName && fileName.indexOf('.') !== -1) {
    return fileName.match(FILE_EXTENSION_REGEX)![0]!;
  } else if (mimeType) {
    return mimeType.split('/').pop();
  }

  return undefined;
}

export function getColorFromExtension(extension: string) {
  switch (extension) {
    case 'apk':
    case 'xls':
    case 'xlsx':
    case 'ods':
      return 'green';
    case 'doc':
    case 'docx':
      return 'blue';
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
    case 'gz':
    case 'bz2':
    case 'liz':
    case 'lz4':
    case 'lz5':
    case 'xz':
    case 'zst':
    case 'wim':
    case 'ppt':
    case 'pptx':
    case 'odp':
      return 'orange';
    case 'pdf':
    case 'xps':
      return 'red';
    case 'png':
      return 'light-blue';
    default:
      return 'default';
  }
}
