import { DeleteOutlined, EnterOutlined, PhoneOutlined, StopOutlined } from '@ant-design/icons';
import type { ObserveFn } from '@hooks/use-intersection-observer';
import { useOnIntersect } from '@hooks/use-intersection-observer';
import useLastCallback from '@hooks/use-last-callback';
import useSocket from '@hooks/use-socket';
import type { ApiUser } from '@interfaces/api-user';
import type { Conversation } from '@interfaces/conversation';
import type { ApiMessage, Message as MessageInterface } from '@interfaces/message';
import {
  fileExceedsTGAPILimit,
  isAudioLikeMessage,
  isDocumentMessage,
  isFileMessage,
  isPhotoMessage,
  isStickerMessage,
  isVideoLikeMessage,
} from '@interfaces/messages';
import EmbeddedMessage from '@pages/ChatFeed/components/EmbeddedMessage';
import Sticker from '@pages/ChatFeed/RightColumn/MessageList/MessageListContent/Message/Sticker';
import { setInputMessageReplyInfo } from '@store/reducers/messages';
import { selectIsMessageFocused } from '@store/selectors/messages';
import { deleteMessage } from '@utils/api/delete-message';
import buildClassName from '@utils/build-class-name';
import { focusMessage } from '@utils/ui/focus-message';
import type { MenuProps } from 'antd';
import { App, Button, Dropdown, Tooltip } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { memo, useRef } from 'react';

import { useBoundStore } from '@/newstore';
import { getConversation } from '@/newstore/selectors/conversation';
import { useForwardedMessage, useForwardedSender, useSender, useSharedActions } from '@/newstore/selectors/shared';

import Audio from './Audio';
import Document from './Document';
import styles from './Message.module.scss';
import MessageMeta from './MessageMeta';
import MessageText from './MessageText';
import Photo from './Photo';
import Video from './Video';

const NBSP = '\u00A0';

function getMessageHtmlId(messageId: number) {
  return `message${messageId.toString().replace('.', '-')}`;
}

function isReplyToMessage(message: MessageInterface) {
  return !!message.reply_to_id;
}

function isEditedMessage(message: MessageInterface) {
  return !!message.edited_message_id;
}

interface MessagePositionProperties {
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  isLastInList: boolean;
}

interface OwnProps extends MessagePositionProperties {
  message: MessageInterface;
  observeIntersectionForBottom: ObserveFn;
  withSenderName?: boolean;
}

interface StateProps {
  sender?: ApiUser;
  originSender?: ApiUser;
  originConversation?: Conversation;
  forwardedMessage?: MessageInterface;
  replyMessage?: ApiMessage;
  replyMessageSender?: ApiUser;
  isFocused?: boolean;
  isForwarding?: boolean;
}

const NonMemoMessage: FC<OwnProps & StateProps> = ({
  message,
  observeIntersectionForBottom,
  withSenderName,
  isFirstInGroup,
  isLastInGroup,
  isLastInList,
  sender,
  originSender,
  originConversation,
  forwardedMessage,
  replyMessage,
  replyMessageSender,
  isFocused,
  isForwarding,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const bottomMarkerRef = useRef<HTMLDivElement>(null);

  const { modal } = App.useApp();

  const socket = useSocket();

  const { openForwardMenu } = useSharedActions();

  useOnIntersect(bottomMarkerRef, observeIntersectionForBottom);

  const setActiveConversation = useBoundStore((state) => state.setActiveConversationId);

  const messageId = (message.id || message.localId) as number;

  // const isLocal = message.localId !== undefined && message.id === undefined;
  const isOwn = message.user_id !== null;
  const hasMessageReply = isReplyToMessage(message);
  const hasText = message.body !== null;
  const hasEditedMessage = isEditedMessage(message);
  const hasSubheader = hasMessageReply || hasEditedMessage;
  const asForwarded = !!message.forward_info;
  const messageSender = asForwarded ? originSender : sender;

  const handleReplyClick = useLastCallback((): void => {
    if (!message.replyMessage || !message.replyMessage.conversation_id) {
      return;
    }

    focusMessage(message.replyMessage.conversation_id, message.replyMessage.id || message.replyMessage.localId);
  });

  const handleEditedClick = useLastCallback((): void => {
    if (!message.editedMessage || !message.editedMessage.conversation_id) {
      return;
    }

    focusMessage(message.editedMessage.conversation_id, message.editedMessage.id || message.editedMessage.localId);
  });

  const handleDoubleClick = useLastCallback(() => {
    if (canBeRepliedTo) {
      addReplyToMessageInfo();
    }
  });

  const handleSenderClick = useLastCallback(() => {
    if (originConversation) {
      setActiveConversation(originConversation.id);
    }
  });

  const created_at = dayjs(message.created_at);
  const canBeRepliedTo = message.tg_message_id && !message.deleted_at;
  const canBeForwarded = (message: MessageInterface): message is ApiMessage & { id: number; conversation_id: number } =>
    message.id !== undefined && message.conversation_id !== undefined && !message.deleted_at && message.type === 'text';
  const isDeleted = message.deleted_by || message.deleted_at;
  const canBeDeleted =
    isOwn && message.type === 'text' && !isDeleted && created_at.add(48, 'hours').isAfter(new Date());

  const addReplyToMessageInfo = useLastCallback(() => {
    setInputMessageReplyInfo(message);
  });

  const contextMenuItems: MenuProps['items'] = [
    ...(canBeForwarded(message)
      ? [
          {
            key: 'forward',
            label: 'Forward',
            icon: <EnterOutlined style={{ transform: 'scale(-1, -1)' }} />,
          },
        ]
      : []),
    ...(canBeRepliedTo
      ? [
          {
            key: 'reply',
            label: 'Reply',
            icon: <EnterOutlined style={{ transform: 'scaleX(-1) rotate(180deg)' }} />,
          },
        ]
      : []),
    ...(canBeDeleted
      ? [
          {
            key: 'delete',
            label: 'Delete',
            icon: <DeleteOutlined />,
          },
        ]
      : []),
  ];

  const onContextMenuClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'reply') {
      addReplyToMessageInfo();
      return;
    } else if (key === 'delete') {
      const confirmed = await modal.confirm({
        title: 'Delete message',
        content: 'Are you sure you want to delete this message?',
        okText: 'Yes',
      });
      if (confirmed) {
        deleteMessage(socket, messageId);
      }
    } else if (key === 'forward' && canBeForwarded(message)) {
      openForwardMenu(message.conversation_id, message.id);
    }
  };

  const containerClassName = buildClassName(
    'message-list-item',
    styles.message,
    isFirstInGroup && styles.firstInGroup,
    isLastInGroup && styles.lastInGroup,
    isLastInList && styles.lastInList,
    isOwn && styles.own,
    isOwn && 'own',
    hasMessageReply && styles.hasReply,
    isFocused && styles.focused,
    isForwarding && styles.isForwarding,
  );

  const contentClassName = buildClassName(
    styles.messageContent,
    styles.hasBackground,
    message.type,
    hasSubheader && styles.hasSubheader,
    asForwarded && styles.isForwarded,
    styles.hasShadow,
    (hasSubheader || asForwarded) && styles.hasSolidBackground,
  );

  function shouldRenderSenderName() {
    return withSenderName || asForwarded;
  }

  function renderForwardTitle() {
    return (
      <span className={styles.forwardTitleContainer}>
        {asForwarded && <EnterOutlined style={{ transform: 'scale(-1, -1)' }} />}
        {asForwarded && <span className={styles.forwardTitle}>Forwarded from</span>}
      </span>
    );
  }

  function renderSenderName() {
    const senderTitle =
      asForwarded && messageSender?.full_name && originConversation
        ? `${originConversation.name} - ${messageSender.full_name}`
        : messageSender?.full_name;

    return (
      <div className={styles.messageTitle} dir="ltr">
        {senderTitle || asForwarded ? (
          <span className={buildClassName(styles.messageTitleNameContainer, styles.interactive)} dir="ltr">
            {renderForwardTitle()}
            <span className={styles.messageTitleName}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span className={styles.senderTitle} onClick={handleSenderClick}>
                {senderTitle ? senderTitle : asForwarded ? NBSP : undefined}
              </span>
            </span>
          </span>
        ) : null}
        <div className="title-spacer" />
        {message.author_phone && (
          <a
            className={styles.messageTitlePhone}
            href={`tel:${message.author_phone}`}
            title={`Call ${message.author_name}`}
          >
            <PhoneOutlined />
          </a>
        )}
      </div>
    );
  }

  function renderMessageText() {
    return message.deleted_at ? (
      <div style={{ color: '#bcc0c1' }}>
        <StopOutlined style={{ marginRight: '0.3rem' }} />
        This{' '}
        <Tooltip title={message.body}>
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>message</span>
        </Tooltip>{' '}
        was deleted from Telegram by {message.deleted_author_name}.
      </div>
    ) : (
      <MessageText message={message.body} />
    );
  }

  function renderContent() {
    const className = buildClassName(
      styles.contentInner,
      asForwarded && styles.forwardedMessage,
      hasSubheader && styles.withSubheader,
    );

    const textContentClass = buildClassName(styles.textContent, styles.clearfix);

    return (
      <div className={className} dir="auto">
        {!asForwarded && shouldRenderSenderName() && renderSenderName()}
        {asForwarded && forwardedMessage?.type === 'service-message' && <em>Service message:</em>}
        {hasSubheader && (
          <div className={styles.messageSubheader}>
            {hasMessageReply && (
              <EmbeddedMessage message={replyMessage} sender={replyMessageSender} onClick={handleReplyClick} />
            )}
            {hasEditedMessage && (
              <EmbeddedMessage message={message.editedMessage as ApiMessage} onClick={handleEditedClick} />
            )}
          </div>
        )}
        {(isDocumentMessage(message) ||
          (isFileMessage(message) && fileExceedsTGAPILimit(message.extra_attributes))) && (
          <Document id={message.id} document={message.extra_attributes} />
        )}
        {isAudioLikeMessage(message) && !fileExceedsTGAPILimit(message.extra_attributes) && (
          <Audio id={message.id} audio={message.extra_attributes} />
        )}
        {isVideoLikeMessage(message) && !fileExceedsTGAPILimit(message.extra_attributes) && (
          <Video id={message.id} video={message.extra_attributes} />
        )}
        {isPhotoMessage(message) && <Photo id={message.id} photos={message.extra_attributes} />}
        {isStickerMessage(message) && !fileExceedsTGAPILimit(message.extra_attributes) && (
          <Sticker id={message.id} sticker={message.extra_attributes} />
        )}
        {hasText && (
          <div className={textContentClass} dir="auto">
            {renderMessageText()}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      id={getMessageHtmlId(messageId)}
      className={containerClassName}
      data-message-id={messageId}
      data-is-local={message.id === undefined && message.localId !== undefined}
      onDoubleClick={handleDoubleClick}
    >
      <div ref={bottomMarkerRef} className="bottom-marker" data-message-id={messageId} data-is-local={!message.id} />
      <Dropdown
        trigger={['contextMenu']}
        menu={{
          items: contextMenuItems,
          onClick: onContextMenuClick,
        }}
      >
        <div className={contentClassName} dir="auto">
          {asForwarded && shouldRenderSenderName() && renderSenderName()}
          {renderContent()}
          {canBeRepliedTo && (
            <Button type="link" className={styles.replyButton} onClick={addReplyToMessageInfo}>
              Reply
            </Button>
          )}
          <MessageMeta className={styles.messageMeta} message={message} isOwn={isOwn} />
        </div>
      </Dropdown>
    </div>
  );
};

const Message = memo(function Message(ownProps: OwnProps) {
  const forwardedMessage = useBoundStore((state) => state.forwardedMessage);
  const isForwarding = forwardedMessage && forwardedMessage.messageId === ownProps.message.id;

  const { message } = ownProps;

  const sender = useSender(message);
  const originSender = useForwardedSender(message);
  const originConversation = message.forward_info?.fromChatId
    ? getConversation(message.forward_info.fromChatId)
    : undefined;
  const originMessage = useForwardedMessage(message);
  const replyMessage = message.replyMessage as ApiMessage;
  const replyMessageSender = useSender(message.replyMessage);
  const isFocused = selectIsMessageFocused(message, message.conversation_id);

  return (
    <NonMemoMessage
      sender={sender}
      originSender={originSender}
      originConversation={originConversation ?? undefined}
      forwardedMessage={originMessage}
      replyMessage={replyMessage}
      replyMessageSender={replyMessageSender}
      isFocused={isFocused}
      isForwarding={isForwarding}
      {...ownProps}
    />
  );
});

export default Message;
