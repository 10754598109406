import { devtools } from 'zustand/middleware';
import { create } from 'zustand/react';
import type { StateCreator } from 'zustand/vanilla';

import type { ConversationsSlice } from './slices/conversations';
import { createConversationsSlice } from './slices/conversations';
import type { MessagesSlice } from './slices/messages';
import { createMessagesSlice } from './slices/messages';
import type { SharedSlice } from './slices/shared';
import { createSharedSlice } from './slices/shared';
import type { UsersSlice } from './slices/users';
import { createUsersSlice } from './slices/users';

export type AllSlices = ConversationsSlice & MessagesSlice & UsersSlice;
export type SliceCreator<T, U = T> = StateCreator<T, [['zustand/devtools', never]], [], U>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Action<T extends SliceCreator<any>, R extends (...args: any[]) => any> = (
  set: Parameters<T>[0],
  get: Parameters<T>[1],
  store: Parameters<T>[2],
) => (...args: Parameters<R>) => ReturnType<R>;

export const useBoundStore = create<AllSlices & SharedSlice>()(
  devtools(
    (...init) => ({
      ...createConversationsSlice(...init),
      ...createMessagesSlice(...init),
      ...createUsersSlice(...init),
      ...createSharedSlice(...init),
    }),
    { name: 'ChatStore' },
  ),
);

export const initConversations = (data: Parameters<ConversationsSlice['conversations']['initConversations']>[0]) => {
  useBoundStore.getState().conversations.initConversations(data);
};

export const initUsers = (data: Parameters<UsersSlice['users']['initUsers']>[0]) => {
  useBoundStore.getState().users.initUsers(data);
};
