import ConversationList from '@pages/ChatFeed/LeftColumn/ConversationList';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useState, useTransition } from 'react';

import { useActiveConversation } from '@/newstore/selectors/shared';

import styles from './LeftColumn.module.scss';
import SearchInput from './SearchInput';

const LeftColumn: FC = memo(function LeftColumn() {
  // TODO: extract state to higher order component
  const { conversation: activeConversation } = useActiveConversation();
  const [, startTransition] = useTransition();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setSearchQuery('');
  }, [activeConversation]);

  const handleSearchQuery = useCallback((query: string) => {
    startTransition(() => {
      setSearchQuery(query);
    });
  }, []);

  return (
    <div className={styles.root}>
      <SearchInput onChange={handleSearchQuery} value={searchQuery} />
      <ConversationList searchQuery={searchQuery} />
    </div>
  );
});

export default LeftColumn;
