import type { ConversationHandler } from '@context/socket.context';
import useMountedEffect from '@hooks/use-mounted-effect';
import useSocket from '@hooks/use-socket';
import { isConversationStatus } from '@interfaces/conversation';
import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '../index';

export function useConversation(id: number, subscribe = true) {
  const socket = useSocket();
  const { conversation, subscribed, isLoadingMessages, setSubscribed, patchConversation } = useBoundStore(
    useShallow((state) => ({
      conversation: state.conversations.byId[id].resource,
      subscribed: state.conversations.byId[id].subscribed,
      isLoadingMessages: state.conversations.byId[id].isLoadingMessages,
      setSubscribed: state.conversations.byId[id].setSubscribed,
      patchConversation: state.conversations.byId[id].patchConversation,
    })),
  );

  const isAuthorized = conversation?.is_authorized ?? false;

  const onConversationHandler = useCallback<ConversationHandler>(
    (update) => {
      if (isConversationStatus(update)) {
        if (isAuthorized) {
          // Update latest message and time
          patchConversation((prev) => ({
            time: update.time,
            latestMessage: update.latestMessage,
            nextCursor: prev.nextCursor || update.messageId,
          }));
        }

        if (update.latestMessage !== 'This message was deleted.') {
          // Update unread counter, increment
          patchConversation((prev) => (prev.cursor !== update.messageId ? { unread: (prev.unread || 0) + 1 } : {}));
        }

        return;
      }

      // Update unread counter from update
      patchConversation({ unread: update.unread, cursor: update.cursor });

      if (isAuthorized !== update.is_authorized) {
        // Update authorization status
        patchConversation({ is_authorized: update.is_authorized });
      }
    },
    [isAuthorized, patchConversation],
  );

  useMountedEffect(() => {
    if (subscribe && !subscribed) {
      socket.on(`conversation/${id}`, onConversationHandler);
      setSubscribed(true);
    }

    return () => {
      if (subscribe && subscribed) {
        socket.off(`conversation/${id}`, onConversationHandler);
        setSubscribed(false);
      }
    };
  }, [id, socket, setSubscribed, subscribe, subscribed]);

  return {
    conversation,
    subscribed,
    isLoadingMessages,
  };
}

export const getConversation = (id: number) => useBoundStore.getState().conversations.byId?.[id]?.resource;
