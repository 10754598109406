import { CloseOutlined, EnterOutlined } from '@ant-design/icons';
import useLastCallback from '@hooks/use-last-callback';
import type { ApiUser } from '@interfaces/api-user';
import type { ApiMessage, ApiMessageReplyInfo } from '@interfaces/message';
import EmbeddedMessage from '@pages/ChatFeed/components/EmbeddedMessage';
import { removeInputMessageReplyInfo } from '@store/reducers/messages';
import { selectInputMessageReplyInfo } from '@store/selectors/messages';
import captureEscKeyListener from '@utils/captureEscKeyListener';
import { focusMessage } from '@utils/ui/focus-message';
import { Button } from 'antd';
import type { FC } from 'react';
import React, { memo, useEffect, useMemo, useRef } from 'react';

import { useBoundStore } from '@/newstore';
import { useMessage } from '@/newstore/selectors/messages';
import { useSender, useSharedActions } from '@/newstore/selectors/shared';

import styles from './ComposerEmbeddedMessage.module.scss';

interface StateProps {
  replyInfo?: ApiMessageReplyInfo;
  message?: ApiMessage;
  sender?: ApiUser;
  isForwarding?: boolean;
}

interface OwnProps {
  chatId: number;
  onClear?: () => void;
}

const NonMemoComposerEmbeddedMessage: FC<OwnProps & StateProps> = ({
  replyInfo,
  message,
  sender,
  isForwarding,
  onClear,
  chatId,
}) => {
  const { exitForwardMode } = useSharedActions();
  const ref = useRef<HTMLDivElement>(null);

  const isShowingReply = replyInfo !== undefined;

  const isShown = (() => {
    if (message && replyInfo) {
      return true;
    }
    return !!(sender && isForwarding);
  })();

  const clearEmbedded = useLastCallback(() => {
    if (isForwarding) {
      exitForwardMode();
    } else if (replyInfo) {
      removeInputMessageReplyInfo();
    }
    onClear?.();
  });

  useEffect(() => {
    if (isShown) {
      return captureEscKeyListener(clearEmbedded);
    }
  }, [isShown, clearEmbedded]);

  const handleMessageClick = useLastCallback(() => {
    focusMessage(chatId, replyInfo?.replyToMsgId);
  });

  const handleClearClick = useLastCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    clearEmbedded();
  });

  const leftIcon = useMemo(() => {
    if (isForwarding) {
      return <EnterOutlined />;
    }
    if (isShowingReply) {
      return <EnterOutlined />;
    }

    return undefined;
  }, [isForwarding, isShowingReply]);

  if (!isShown) {
    return undefined;
  }

  return (
    <div className={styles.composerEmbeddedMessage} ref={ref}>
      <div className={styles.composerEmbeddedMessageInner}>
        <div className={styles.embeddedLeftIcon}>{leftIcon}</div>
        <EmbeddedMessage
          className="inside-input"
          replyInfo={replyInfo}
          sender={sender}
          onClick={handleMessageClick}
          message={message}
        />
        <Button
          type="default"
          size="large"
          className={styles.embeddedCancel}
          icon={<CloseOutlined />}
          onClick={handleClearClick}
        />
      </div>
    </div>
  );
};

const ComposerEmbeddedMessage = memo<OwnProps>(function ComposerEmbeddedMessage(props: OwnProps) {
  const inputMessageReplyInfo = selectInputMessageReplyInfo();
  const forwardedMessage = useBoundStore((state) => state.forwardedMessage);
  const isForwarding = forwardedMessage?.toChatId !== undefined && props.chatId === forwardedMessage.toChatId;

  const replyInfo: ApiMessageReplyInfo | undefined = inputMessageReplyInfo
    ? { replyToMsgId: inputMessageReplyInfo.id! }
    : undefined;

  const messageId = forwardedMessage?.messageId || replyInfo?.replyToMsgId;
  const message = useMessage(messageId);
  const sender = useSender(message);

  return (
    <NonMemoComposerEmbeddedMessage
      replyInfo={replyInfo}
      message={message as ApiMessage}
      sender={sender}
      isForwarding={isForwarding}
      onClear={props.onClear}
      chatId={props.chatId}
    />
  );
});

export default ComposerEmbeddedMessage;
