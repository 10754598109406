import { replaceLocalMessage } from '../actions/messages';
import {
  exitForwardMode,
  forwardMessage,
  loadAllUnreadMessages,
  loadConversationMessages,
  loadNewUnreadMessages,
  openForwardMenu,
  sendAttachments,
  sendMessage,
  setForwardChat,
} from '../actions/shared';
import type { Action, AllSlices, SliceCreator } from '../index';

export interface ForwardedMessage {
  fromChatId: number;
  messageId: number;
  toChatId?: number;
}

// Global Slice

interface SharedState {
  activeConversationId?: number;
  forwardedMessage?: ForwardedMessage;
  isForwardModalOpen: boolean;
}

const initialState: SharedState = {
  activeConversationId: undefined,
  isForwardModalOpen: false,
};

interface SharedActions {
  setActiveConversationId: (id: number) => void;
  loadConversationMessages: ReturnType<typeof loadConversationMessages>;
  loadUnreadMessages: ReturnType<typeof loadAllUnreadMessages>;
  loadNewUnreadMessages: ReturnType<typeof loadNewUnreadMessages>;
  sendMessage: ReturnType<typeof sendMessage>;
  sendAttachments: ReturnType<typeof sendAttachments>;
  replaceLocalMessage: ReturnType<typeof replaceLocalMessage>;
  openForwardMenu: ReturnType<typeof openForwardMenu>;
  exitForwardMode: ReturnType<typeof exitForwardMode>;
  setForwardChat: ReturnType<typeof setForwardChat>;
  forwardMessage: ReturnType<typeof forwardMessage>;
}

export type SharedSlice = SharedState & SharedActions;

export const createSharedSlice: SliceCreator<AllSlices & SharedSlice, SharedSlice> = (set, get, store) => ({
  ...initialState,
  setActiveConversationId: (id) =>
    set(
      (state) => ({
        ...state,
        activeConversationId: id,
      }),
      undefined,
      'setActiveConversationId',
    ),
  loadConversationMessages: loadConversationMessages(set, get, store),
  loadUnreadMessages: loadAllUnreadMessages(set, get, store),
  loadNewUnreadMessages: loadNewUnreadMessages(set, get, store),
  sendMessage: sendMessage(set, get, store),
  sendAttachments: sendAttachments(set, get, store),
  replaceLocalMessage: replaceLocalMessage(set, get, store),
  openForwardMenu: openForwardMenu(set, get, store),
  exitForwardMode: exitForwardMode(set, get, store),
  setForwardChat: setForwardChat(set, get, store),
  forwardMessage: forwardMessage(set, get, store),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SharedStoreAction<R extends (...args: any[]) => any> = Action<typeof createSharedSlice, R>;
