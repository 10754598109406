import type { AuthContextType } from '@context/auth.context';
import { apiRequest, isSuccessfulDataResponse } from '@services/fetcher';

import type { ConversationsStoreAction } from '../slices/conversations';

export const updateChatLastReadId: ConversationsStoreAction<(chatId: number, messageId: number) => void> =
  (set) => (chatId, messageId) => {
    set((state) => {
      const conversation = state.conversations.byId[chatId];
      if (!conversation || !conversation.resource) {
        return state;
      }

      if (conversation.resource.cursor > messageId) {
        return state;
      }

      return {
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [chatId]: {
              ...conversation,
              resource: {
                ...conversation.resource,
                cursor: messageId,
              },
            },
          },
        },
      };
    });
  };

export const updateChatNextCursor: ConversationsStoreAction<(chatId: number, nextCursor?: number) => void> =
  (set) => (chatId, nextCursor) => {
    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [chatId]: {
              ...state.conversations.byId[chatId],
              resource: {
                ...state.conversations.byId[chatId].resource!,
                nextCursor,
              },
            },
          },
        },
      }),
      undefined,
      'updateChatNextCursor',
    );
  };

export const updateChatPreviousCursor: ConversationsStoreAction<(chatId: number, previousCursor?: number) => void> =
  (set) => (chatId, previousCursor) => {
    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [chatId]: {
              ...state.conversations.byId[chatId],
              resource: {
                ...state.conversations.byId[chatId].resource!,
                previousCursor,
              },
            },
          },
        },
      }),
      undefined,
      'updateChatPreviousCursor',
    );
  };

export const updateChatCursors: ConversationsStoreAction<
  (chatId: number, previousCursor?: number, nextCursor?: number) => void
> = (set) => (chatId, previousCursor, nextCursor) => {
  set(
    (state) => ({
      conversations: {
        ...state.conversations,
        byId: {
          ...state.conversations.byId,
          [chatId]: {
            ...state.conversations.byId[chatId],
            resource: {
              ...state.conversations.byId[chatId].resource!,
              previousCursor,
              nextCursor,
            },
          },
        },
      },
    }),
    undefined,
    'updateChatCursors',
  );
};

export const markChatRead: ConversationsStoreAction<(chatId: number, auth: AuthContextType) => Promise<void>> =
  (set) => async (chatId, auth) => {
    const response = await apiRequest<{ cursor: number }>(
      `${import.meta.env.VITE_API_URL}/conversation/${chatId}/mark-read`,
      auth,
      {
        method: 'post',
      },
    );

    if (!isSuccessfulDataResponse(response)) {
      throw new Error('Error encountered while marking conversation as read.');
    }

    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [chatId]: {
              ...state.conversations.byId[chatId],
              resource: {
                ...state.conversations.byId[chatId].resource!,
                unread: 0,
                cursor: response.cursor,
              },
            },
          },
        },
      }),
      undefined,
      'markChatRead',
    );
  };

export const markChatUnread: ConversationsStoreAction<(chatId: number, auth: AuthContextType) => Promise<void>> =
  (set) => async (chatId, auth) => {
    const response = await apiRequest<{ cursor: number | null }>(
      `${import.meta.env.VITE_API_URL}/conversation/${chatId}/mark-unread`,
      auth,
      {
        method: 'post',
      },
    );

    if (!isSuccessfulDataResponse(response)) {
      throw new Error('Error encountered while marking conversation as unread.');
    }

    const { cursor } = response;

    if (cursor === null) {
      // trying to mark unread empty conversation
      return;
    }

    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [chatId]: {
              ...state.conversations.byId[chatId],
              resource: {
                ...state.conversations.byId[chatId].resource!,
                unread: 1,
                cursor: cursor,
              },
            },
          },
        },
      }),
      undefined,
      'markChatUnread',
    );
  };
