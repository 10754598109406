const kindOf = (
  (cache: Record<string, string>): ((thing: unknown) => string) =>
  (thing) => {
    const str = Object.prototype.toString.call(thing);
    return cache[str] || (cache[str] = str.slice(8, -1).toLowerCase());
  }
)(Object.create(null));

const kindOfTest = (type: string) => {
  type = type.toLowerCase();
  return (thing: unknown) => kindOf(thing) === type;
};

const isDate = kindOfTest('Date');
const isObject = (thing: unknown) => thing !== null && typeof thing === 'object';

const isPlainObject = (val: unknown) => {
  if (kindOf(val) !== 'object') {
    return false;
  }

  const prototype = Object.getPrototypeOf(val);
  return (
    (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in (val as object)) &&
    !(Symbol.iterator in (val as object))
  );
};

function forEach(
  obj: unknown,
  fn: (element: unknown, key: number | string, object: unknown) => void,
  { allOwnKeys = false } = {},
) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  let i;
  let l;

  // Force an array if not already something iterable
  if (typeof obj !== 'object') {
    /*eslint no-param-reassign:0*/
    obj = [obj];
  }

  if (Array.isArray(obj)) {
    // Iterate over array values
    for (i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    const keys = allOwnKeys ? Object.getOwnPropertyNames(obj) : Object.keys(obj as Record<string, unknown>);
    const len = keys.length;
    let key;

    for (i = 0; i < len; i++) {
      key = keys[i];
      fn.call(null, (obj as Record<string, unknown>)[key], key, obj);
    }
  }
}

function isVisitable(thing: unknown) {
  return isPlainObject(thing) || Array.isArray(thing);
}

function isFlatArray(array: unknown[]) {
  return Array.isArray(array) && !array.some(isVisitable);
}

function removeBrackets(key: string) {
  return key.endsWith('[]') ? key.slice(0, -2) : key;
}

function renderKey(path: string[], key: string, dots?: true) {
  return path
    .concat(key)
    .map(function each(token, i) {
      // eslint-disable-next-line no-param-reassign
      token = removeBrackets(token);
      return !dots && i ? '[' + token + ']' : token;
    })
    .join(dots ? '.' : '');
}

interface Options {
  prefix?: string;
  indexes?: true | null;
  dots?: true;
}

function toFormData(obj: unknown, fd?: FormData, options?: Options) {
  if (!isObject(obj)) {
    throw new TypeError('target must be an object');
  }

  const formData = fd || new FormData();
  const indexes = options?.indexes;
  const dots = options?.dots;

  function convertValue(value: unknown): string | Blob {
    if (value === null || value === undefined) {
      return '';
    }

    if (isDate(value)) {
      return (value as Date).toISOString();
    }

    // TODO: Blob conversion

    return value.toString();
  }

  function visitor(value: unknown, key: string | number, path?: string[]) {
    const array = value;
    let normalizedKey = String(key);

    if (value && !path && typeof value === 'object') {
      if (normalizedKey.endsWith('{}')) {
        normalizedKey = normalizedKey.slice(0, -2);
        value = JSON.stringify(value);
      } else if (Array.isArray(value) && isFlatArray(value)) {
        normalizedKey = removeBrackets(normalizedKey);

        (array as unknown[]).forEach(function each(element, index) {
          if (!(typeof element === 'undefined' || element === null)) {
            formData.append(
              indexes === true
                ? renderKey([normalizedKey], index.toString(), dots)
                : indexes === null
                  ? normalizedKey
                  : `${normalizedKey}[]`,
              convertValue(element),
            );
          }
        });

        return false;
      }
    }

    if (isVisitable(value)) {
      return true;
    }

    formData.append(renderKey(path || [], normalizedKey, dots), convertValue(value));

    return false;
  }

  const stack: unknown[] = [];

  function build(value: unknown, path?: string[]) {
    if (typeof value === 'undefined') {
      return;
    }

    if (stack.indexOf(value) !== -1) {
      throw Error('Circular reference detected in ' + (path || []).join('.'));
    }

    stack.push(value);

    forEach(value, function (element, key) {
      const result =
        !(typeof element === 'undefined' || element === null) &&
        visitor.call(formData, element, typeof key === 'string' ? key.trim() : key, path);

      if (result) {
        build(element, path ? path.concat(key.toString()) : [key.toString()]);
      }
    });

    stack.pop();
  }

  build(obj);

  return formData;
}

export default toFormData;
