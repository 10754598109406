import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons';
import { getColorFromExtension, getFileSizeString } from '@helpers/document-info';
import { getDocumentThumbnailDimensions } from '@helpers/media-dimensions';
import useLastCallback from '@hooks/use-last-callback';
import { fileSizeExceedsTGAPILimit } from '@interfaces/messages';
import buildClassName from '@utils/build-class-name';
import type { FC, MouseEventHandler } from 'react';
import { useRef, useState } from 'react';

import styles from './File.module.scss';

interface OwnProps {
  name: string;
  extension?: string;
  size?: number;
  previewData?: string;
  className?: string;
  small?: boolean;
  file_id?: string;
  onDownloadClick?: () => Promise<void>;
}

const File: FC<OwnProps> = ({
  name,
  extension = '',
  size,
  previewData,
  className,
  small,
  file_id,
  onDownloadClick,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const color = getColorFromExtension(extension);
  const sizeString = size ? getFileSizeString(size) : undefined;
  const { width, height } = getDocumentThumbnailDimensions(small);

  const [shouldSpinnerRender, setShouldSpinnerRender] = useState(false);

  const isInteractive = onDownloadClick !== undefined && size !== undefined && !fileSizeExceedsTGAPILimit(size);
  const onIconClick: MouseEventHandler<HTMLDivElement> = useLastCallback(async (event) => {
    event.preventDefault();
    setShouldSpinnerRender(true);
    await onDownloadClick?.();
    setShouldSpinnerRender(false);
  });

  const fullClassName = buildClassName(className, small && 'smaller', styles.file, isInteractive && styles.interactive);

  return (
    <div ref={elementRef} className={fullClassName}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={styles.fileIconContainer}
        onClick={isInteractive && !shouldSpinnerRender ? onIconClick : undefined}
      >
        {previewData ? (
          <div className={styles.filePreview}>
            <img
              src={previewData}
              className={styles.fullMedia}
              width={width}
              height={height}
              draggable={false}
              alt=""
            />
          </div>
        ) : (
          <div className={`${styles.fileIcon} ${color}`}>
            {extension.length <= 4 && (
              <span className={styles.fileExt} dir="auto">
                {extension}
              </span>
            )}
          </div>
        )}
        {shouldSpinnerRender && (
          <div className={`${styles.fileProgress} ${color}`}>
            <LoadingOutlined />
          </div>
        )}
        {isInteractive && (
          <div className={buildClassName(styles.actionIcon, shouldSpinnerRender && styles.hidden)}>
            <ArrowDownOutlined />
          </div>
        )}
      </div>
      <div className={styles.fileInfo}>
        <div className={styles.fileTitle} dir="auto" title={name}>
          {name}
        </div>
        {sizeString && (
          <div className={styles.fileSubtitle} dir="auto">
            <span>{sizeString}</span>
            {/* LEGACY: for older messages we were replacing file_id with appropriate message */}
            {!isInteractive && file_id && <span>{file_id}</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default File;
