import type { SetStateAction } from 'react';
import { useLayoutEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stores: Record<string, InternalStore<any>> = {};

type SetState<TState> = (state: SetStateAction<TState>) => void;
type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export interface Store<TState> {
  readonly name: string;
  readonly defaultState: TState;
  readonly getState: () => TState;
  readonly setState: SetState<TState>;
  readonly useStore: () => [TState, SetState<TState>];
  readonly useSelector: <TValue>(
    selector: (state: TState) => TValue,
    equalityFunction?: (value: TValue, newValue: TValue) => boolean,
  ) => TValue;
  readonly reset: () => void;
}

interface InternalStore<TState> extends Store<TState> {
  state: TState;
  setters: SetState<TState>[];
  subscribe: (listener: (state: TState) => void) => void;
  unsubscribe: (listener: (state: TState) => void) => void;
}

export const createStore = <TState>(name: string, defaultState: TState) => {
  if (stores[name]) {
    console.warn(`[useStore] Store with name '${name}' already exists. Overriding`);
  }

  if (process.env.NODE_ENV !== 'production') {
    console.debug(`Creating store: ${name} with ${defaultState} state.`);
  }

  const store: InternalStore<TState> = {
    name,
    defaultState,
    state: defaultState,
    setters: [],
    getState: () => store.state,
    setState: (setStateAction: SetStateAction<TState>) => {
      store.state =
        typeof setStateAction === 'function'
          ? (setStateAction as (prevState: TState) => TState)(store.state)
          : setStateAction;

      store.setters.forEach((setter) => setter(store.state));
      return store.state;
    },
    useStore: () => useStore(name),
    useSelector: <TValue>(selector: (state: TState) => TValue) => useSelector(name, selector),
    subscribe: (listener: (state: TState) => void) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return store.setters.unshift(listener);
    },
    unsubscribe: (listener: (state: TState) => void) => {
      store.setters = store.setters.filter((setter) => setter !== listener);
    },
    reset: () => store.setState(defaultState),
  };

  stores[name] = store;

  type ReturnArray = [Store<TState>['getState'], Store<TState>['setState'], Store<TState>['useStore']];

  const returnValue = [store.getState, store.setState, store.useStore] as ReturnArray & Writeable<Store<TState>>;

  returnValue.name = name;
  returnValue.getState = store.getState;
  returnValue.setState = store.setState;
  returnValue.useStore = store.useStore;
  returnValue.useSelector = store.useSelector;
  returnValue.reset = store.reset;

  return returnValue as ReturnArray & Store<TState>;
};

export const getStore = <TState>(name: string): InternalStore<TState> => {
  if (!stores[name]) {
    console.debug(`[useStore] Store named "${name}" does not exist, creating...`);
    createStore(name, undefined);
  }

  return stores[name];
};

// const hasStore = (name: string) => !!stores[name];

export const deleteStore = (name: string) => {
  delete stores[name];
};

// const deleteAllStores = () => Object.keys(stores).forEach(deleteStore);

export const useStore = <TState>(name: string): [TState, SetState<TState>] => {
  const store = getStore<TState>(name);
  const [, setState] = useState(store.state);

  useLayoutEffect(() => {
    store.subscribe(setState);

    return () => store.unsubscribe(setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return [store.state, store.setState];
};

export const useSelector = <TState, TValue>(
  name: string,
  selectorFn: (state: TState) => TValue,
  equalityFunction: (value: TValue, newValue: TValue) => boolean = (a, b) => JSON.stringify(a) === JSON.stringify(b),
) => {
  const store = getStore<TState>(name);
  const [value, setValue] = useState(selectorFn(store.state));

  useLayoutEffect(() => {
    const selector = (state: TState) =>
      setValue((value) => {
        const newValue = selectorFn(state);
        return equalityFunction(value, newValue) ? value : newValue;
      });

    store.subscribe(selector);
    return () => store.unsubscribe(selector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectorFn]);

  return value;
};
