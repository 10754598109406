import { FILE_EXTENSION_REGEX } from '@helpers/document-info';
import type { Attachment } from '@interfaces/attachment';
import { preloadImage } from '@utils/file';
import { scaleImage } from '@utils/image-resize';

const MAX_THUMB_IMG_SIZE = 40; // px
const MAX_ASPECT_RATIO = 20;

const GIF_MIME_TYPE = 'image/gif';
export const SUPPORTED_IMAGE_CONTENT_TYPES = new Set(['image/png', 'image/jpeg', GIF_MIME_TYPE]);
export const SUPPORTED_VIDEO_CONTENT_TYPES = new Set(['video/mp4', 'video/quicktime']);
export const SUPPORTED_AUDIO_CONTENT_TYPES = new Set([
  'audio/mp3',
  'audio/ogg',
  'audio/wav',
  'audio/mpeg',
  'audio/flac',
  'audio/aac',
  'audio/m4a',
  'audio/mp4',
  'audio/x-m4a',
]);

export default async function buildAttachment(filename: string, blob: Blob): Promise<Attachment> {
  const blobUrl = URL.createObjectURL(blob);
  const { size } = blob;
  let { type: mimeType } = blob;
  let quick: Attachment['quick'];
  let previewBlobUrl: string | undefined = undefined;
  let shouldSendAsFile: boolean | undefined = undefined;

  // TODO: can build thumbnails here (by building preview blobs) to show before sending

  // Fix for QuickTime files
  if (mimeType === '' && blob instanceof File) {
    const extension = blob.name.match(FILE_EXTENSION_REGEX)?.[0];
    // Is it safe to assume that QuickTime video is with mp4 codec, or extension check is enough?
    if (extension === '.mov') {
      mimeType = 'video/quicktime';
    }
  }

  if (SUPPORTED_IMAGE_CONTENT_TYPES.has(mimeType)) {
    const img = await preloadImage(blobUrl);
    const { width, height } = img;
    shouldSendAsFile = !validateAspectRatio(width, height);

    if (!shouldSendAsFile) {
      // TODO: compress files optionally

      // Fix uncommon jpeg extensions
      if (mimeType === 'image/jpeg') {
        filename = filename.replace(FILE_EXTENSION_REGEX, '.jpg');
      }

      quick = { width, height };
    }

    const shouldShrinkPreview = Math.max(width, height) > MAX_THUMB_IMG_SIZE;
    if (shouldShrinkPreview) {
      previewBlobUrl = await scaleImage(blobUrl, MAX_THUMB_IMG_SIZE / Math.max(width, height), 'image/jpeg');
    } else {
      previewBlobUrl = blobUrl;
    }
  }

  // TODO: revoke blob urls when attachments are synced back to client
  return {
    blobUrl,
    filename,
    mimeType,
    size,
    quick,
    previewBlobUrl,
    shouldSendAsFile,
    uniqueId: `${Date.now()}-${Math.random()}`,
    file: new File([blob], filename, { type: mimeType }),
  };
}

function validateAspectRatio(width: number, height: number) {
  const maxAspectRatio = Math.max(width, height) / Math.min(width, height);
  return maxAspectRatio <= MAX_ASPECT_RATIO;
}

export function getAttachmentType(attachment: Attachment): 'photo' | 'video' | 'audio' | 'document' {
  if (SUPPORTED_IMAGE_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'photo';
  }

  if (SUPPORTED_VIDEO_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'video';
  }

  if (SUPPORTED_AUDIO_CONTENT_TYPES.has(attachment.mimeType)) {
    return 'audio';
  }

  return 'document';
}

export function getImageDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve({ width: image.width, height: image.height });
    image.src = url;
  });
}

export function getVideoDimensions(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => resolve({ width: video.videoWidth, height: video.videoHeight }));
    video.src = url;
  });
}
