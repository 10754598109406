import type { Message } from '@interfaces/message';

import {
  addChatMessagesById,
  addMessage,
  addMessages,
  markMessageRead,
  replaceChatMessages,
  replaceMessage,
  replaceMessages,
} from '../actions/messages';
import type { Action, AllSlices, SliceCreator } from '../index';

interface RouteState {
  byId: Record<number, Message>;
  allIds: number[];
  byConversationId: Record<number, number[]>;
}

const initialValues: RouteState = {
  byId: {},
  allIds: [],
  byConversationId: {},
};

interface ResourceActions {
  replaceChatMessages: ReturnType<typeof replaceChatMessages>;
  addChatMessagesById: ReturnType<typeof addChatMessagesById>;
  addMessages: ReturnType<typeof addMessages>;
  addMessage: ReturnType<typeof addMessage>;
  replaceMessages: ReturnType<typeof replaceMessages>;
  replaceMessage: ReturnType<typeof replaceMessage>;
  markMessageRead: ReturnType<typeof markMessageRead>;
}

export interface MessagesSlice {
  messages: RouteState & ResourceActions;
}

export const createMessagesSlice: SliceCreator<AllSlices, MessagesSlice> = (set, get, store) => ({
  messages: {
    ...initialValues,
    markMessageRead: markMessageRead(set, get, store),
    replaceChatMessages: replaceChatMessages(set, get, store),
    addChatMessagesById: addChatMessagesById(set, get, store),
    addMessages: addMessages(set, get, store),
    addMessage: addMessage(set, get, store),
    replaceMessages: replaceMessages(set, get, store),
    replaceMessage: replaceMessage(set, get, store),
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageStoreAction<R extends (...args: any[]) => any> = Action<typeof createMessagesSlice, R>;
