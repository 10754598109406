import useLastCallback from '@hooks/use-last-callback';
import { EDITABLE_INPUT_ID } from '@pages/ChatFeed/RightColumn/constants';
import { IS_TOUCH_ENV } from '@pages/Management/utils/environment';
import buildClassName from '@utils/build-class-name';
import focusEditableElement from '@utils/focus-editable-element';
import type { KeyboardEvent, SyntheticEvent } from 'react';
import { forwardRef, memo, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';

import styles from './MessageInput.module.scss';

const SAFARI_BR = '<br>';
const IGNORE_KEYS = [
  'Esc',
  'Escape',
  'Enter',
  'PageUp',
  'PageDown',
  'Meta',
  'Alt',
  'Ctrl',
  'ArrowDown',
  'ArrowUp',
  'Control',
  'Shift',
];

interface OwnProps {
  id: string;
  editableInputId?: string;
  conversationId: number;
  getHtml: () => string;
  placeholder: string;
  canAutoFocus: boolean;
  onUpdate: (html: string) => void;
  onSend: () => void;
  withMessageTemplatesButton?: boolean;
  isActive: boolean;
}

// eslint-disable-next-line
const NonMemoMessageInput = forwardRef<HTMLDivElement, OwnProps>(
  (
    {
      id,
      editableInputId,
      conversationId,
      getHtml,
      placeholder,
      canAutoFocus,
      onUpdate,
      onSend,
      withMessageTemplatesButton = false,
      isActive,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    const htmlRef = useRef(getHtml());
    useLayoutEffect(() => {
      const html = isActive ? getHtml() : '';

      if (html !== inputRef.current!.innerHTML) {
        inputRef.current!.innerHTML = html;
      }

      if (html !== htmlRef.current) {
        htmlRef.current = html;
      }
    }, [getHtml, isActive]);

    function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSend();
      }
    }

    function handleChange(e: SyntheticEvent<HTMLDivElement>) {
      const { innerHTML } = e.currentTarget;

      onUpdate(innerHTML === SAFARI_BR ? '' : innerHTML);
    }

    const focusInput = useLastCallback(() => {
      if (!inputRef.current) {
        return;
      }

      focusEditableElement(inputRef.current);
    });

    // Focus input when first switching to conversation
    useEffect(() => {
      if (IS_TOUCH_ENV) {
        return;
      }

      if (canAutoFocus) {
        focusInput();
      }
    }, [conversationId, focusInput, canAutoFocus]);

    // Focusing element automatically when typing and the input is not focused
    useEffect(() => {
      if (editableInputId !== EDITABLE_INPUT_ID) {
        return;
      }

      const handleDocumentKeyDown = (e: GlobalEventHandlersEventMap['keydown']) => {
        const { key } = e;
        const target = e.target as HTMLElement | undefined;

        if (!target || IGNORE_KEYS.includes(key)) {
          return;
        }

        const input = inputRef.current!;
        const isSelectionCollapsed = document.getSelection()?.isCollapsed;

        if (
          ((key.startsWith('Arrow') || (e.shiftKey && key === 'Shift')) && !isSelectionCollapsed) ||
          (e.code === 'KeyC' && (e.ctrlKey || e.metaKey) && target.tagName !== 'INPUT')
        ) {
          return;
        }

        if (
          input &&
          target !== input &&
          target.tagName !== 'INPUT' &&
          target.tagName !== 'TEXTAREA' &&
          !target.isContentEditable
        ) {
          focusEditableElement(input, true, true);
          const newEvent = new KeyboardEvent(e.type, e as unknown as KeyboardEventInit);
          input.dispatchEvent(newEvent);
        }
      };

      document.addEventListener('keydown', handleDocumentKeyDown, true);

      return () => {
        document.removeEventListener('keydown', handleDocumentKeyDown, true);
      };
    }, [conversationId, editableInputId]);

    const isTouched = Boolean(getHtml());

    const className = buildClassName(styles.formControl, isTouched && 'touched');

    return (
      <div className={buildClassName(withMessageTemplatesButton && styles.withTemplatesButton)} id={id}>
        <div
          ref={inputRef}
          id={editableInputId || EDITABLE_INPUT_ID}
          className={className}
          contentEditable
          role="textbox"
          dir="auto"
          tabIndex={0}
          onClick={focusInput}
          onInput={handleChange}
          onBlur={handleChange}
          onKeyDown={handleKeyDown}
          aria-label={placeholder}
        />
        <span className="placeholder-text" dir="auto">
          {placeholder}
        </span>
      </div>
    );
  },
);

const MessageInput = memo(NonMemoMessageInput);

export default MessageInput;
