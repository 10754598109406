import buildClassName from '@utils/build-class-name';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import type { SortMatchResultType } from 'string-comparison';
import stringComparison from 'string-comparison';

import { useConversations } from '@/newstore/selectors/conversations';
import { useActiveConversation, useSharedActions } from '@/newstore/selectors/shared';
import type { ConversationItem } from '@/newstore/slices/conversation';

import styles from './ConversationList.module.scss';
import ConversationListItem from './ConversationListItem';

interface OwnProps {
  searchQuery: string;
  activeChatId?: number;
}

interface StateProps {
  conversations: ConversationItem[];
}

const NonMemoConversationList: FC<OwnProps & StateProps> = ({ activeChatId, conversations, searchQuery }) => {
  const { setActiveConversationId } = useSharedActions();
  const filteredConversations = useMemo(() => {
    const query = searchQuery.trim();
    if (query === '') {
      return conversations;
    }

    const lowerCaseQuery = searchQuery.toLowerCase();
    const RATING_THRESHOLD = 0;
    const INCLUSION_WEIGHT = 0.1;
    const sortedNames = new Map<string, SortMatchResultType>(
      stringComparison.diceCoefficient
        .sortMatch(
          searchQuery,
          conversations.map((conversation) => conversation.name),
        )
        .map((sorted) => {
          const includesSearchQuery = sorted.member.toLowerCase().includes(lowerCaseQuery);
          if (includesSearchQuery) {
            sorted.rating += INCLUSION_WEIGHT;
          } else {
            sorted.rating -= INCLUSION_WEIGHT;
          }

          sorted.rating = sorted.rating < 0 ? 0 : sorted.rating;

          return [sorted.member, sorted];
        }),
    );

    return conversations
      .filter((conversation) => {
        const rating = sortedNames.get(conversation.name)?.rating;
        return searchQuery.length === 1 || (rating && rating > RATING_THRESHOLD);
      })
      .sort((conversation1, conversation2) => {
        const rating1 = sortedNames.get(conversation1.name)?.rating;
        const rating2 = sortedNames.get(conversation2.name)?.rating;

        return (rating1 !== undefined && rating2 !== undefined && rating2 - rating1) || 0;
      });
  }, [conversations, searchQuery]);

  return (
    <div className={buildClassName(styles.conversationList, 'custom-scroll')}>
      {filteredConversations.map((conversation) => (
        // TODO: set proper href?
        // eslint-disable-next-line
        <a
          key={conversation.id}
          className={!conversation.is_authorized ? styles.locked : ''}
          onClick={() => setActiveConversationId(conversation.id)}
        >
          <ConversationListItem id={conversation.id} active={activeChatId === conversation.id} />
          {/*{({ isActive }) => <ConversationListItem id={conversation.id} active={isActive} />}*/}
        </a>
      ))}
    </div>
  );
};

const ConversationList = memo(function ConversationList({ searchQuery }: OwnProps) {
  const { conversations } = useConversations();
  const { conversation: activeConversation } = useActiveConversation();

  return (
    <NonMemoConversationList
      searchQuery={searchQuery}
      conversations={conversations}
      activeChatId={activeConversation?.id}
    />
  );
});

export default ConversationList;
