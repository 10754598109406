import type { AuthContextType } from '@context/auth.context';
import type { ApiUser } from '@interfaces/api-user';
import type { Conversation } from '@interfaces/conversation';
import { apiRequest, isResponse } from '@services/fetcher';
import type { LoaderFunction } from 'react-router-dom';

import { initConversations, initUsers } from '@/newstore';

export const loader = (auth: AuthContextType) =>
  (async ({ request }) => {
    const response = await apiRequest<Conversation[]>(`${import.meta.env.VITE_API_URL}/conversation`, auth, {
      signal: request.signal,
    });
    // TODO: temporary, users fetching need to be done in store, new fetcher needed
    const users = await apiRequest<ApiUser[]>(`${import.meta.env.VITE_API_URL}/organization/users`, auth, {
      signal: request.signal,
    });

    if (isResponse(response)) {
      return {
        status: response.status,
        statusText: response.statusText,
      } as Response;
    }

    if (response instanceof Error) {
      throw response;
    }

    // return { conversations: response };
    initConversations(response);

    if (isResponse(users)) {
      return {
        status: users.status,
        statusText: users.statusText,
      } as Response;
    }

    if (users instanceof Error) {
      throw users;
    }

    initUsers(users);

    return response;
  }) satisfies LoaderFunction;
