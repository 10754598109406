import type { TypedSocket } from '@context/socket.context';
import type { Message } from '@interfaces/message';
import { debounce } from '@utils/schedulers';

import type { createMessagesSlice, MessageStoreAction } from '../slices/messages';
import type { SharedStoreAction } from '../slices/shared';

export const replaceChatMessages: MessageStoreAction<(chatId: number, newById: Record<number, Message>) => void> =
  (set) => (chatId, newById) => {
    set(
      (state) => {
        const newIds = Object.keys(newById).map(Number);

        return {
          messages: {
            ...state.messages,
            byId: {
              ...state.messages.byId,
              ...newById,
            },
            allIds: [...new Set([...state.messages.allIds, ...newIds])],
            byConversationId: {
              ...state.messages.byConversationId,
              [chatId]: newIds,
            },
          },
        };
      },
      undefined,
      'replaceChatMessages',
    );
  };

export const addChatMessagesById: MessageStoreAction<(chatId: number, newById: Record<number, Message>) => void> =
  (_, get) => (chatId, newById) => {
    const chatMessages = get().messages.byConversationId?.[chatId];

    if (chatMessages && Object.keys(newById).every((newId) => chatMessages.includes(Number(newId)))) {
      return;
    }

    const byId = Object.fromEntries(
      Object.entries(get().messages.byId)
        .filter(([, message]) => message.conversation_id === chatId)
        .map(([id, message]) => [Number(id), message]),
    ) as Record<number, Message>;
    get().messages.replaceChatMessages(chatId, { ...byId, ...newById });
  };

function groupByChatId(messages: Message[]): Record<number, Record<number, Message>> {
  return messages.reduce<Record<number, Record<number, Message>>>((messagesByChatId, message) => {
    if (!messagesByChatId[message.conversation_id]) {
      messagesByChatId[message.conversation_id] = {};
    }

    messagesByChatId[message.conversation_id][(message.id || message.localId)!] = message;

    return messagesByChatId;
  }, {});
}

export const addMessages: MessageStoreAction<(messages: Message[]) => void> = (_, get) => (messages) => {
  const addedByChatId = groupByChatId(messages);

  for (const chatId in addedByChatId) {
    get().messages.addChatMessagesById(Number(chatId), addedByChatId[chatId]);
  }
};

export const addMessage: MessageStoreAction<(message: Message) => void> = (_, get) => (message) => {
  get().messages.addMessages([message]);
};

export const replaceMessages: MessageStoreAction<(messages: Message[]) => void> = (_, get) => (messages) => {
  const updatedByChatId = groupByChatId(messages);

  for (const chatId in updatedByChatId) {
    const currentById = Object.fromEntries(
      Object.entries(get().messages.byId)
        .filter(([, message]) => message.conversation_id === Number(chatId))
        .map(([id, message]) => [Number(id), message]),
    ) as Record<number, Message>;
    const newById = {
      ...currentById,
      ...updatedByChatId[chatId],
    };
    get().messages.replaceChatMessages(Number(chatId), newById);
  }
};

export const replaceMessage: MessageStoreAction<(messages: Message) => void> = (_, get) => (message) => {
  get().messages.replaceMessages([message]);
};

export const replaceLocalMessage: SharedStoreAction<(message: Message) => void> = (set, get) => (message) => {
  if (message.id === undefined || message.localId === undefined) {
    return;
  }

  const localId = message.localId;
  const newId = message.id;
  const chatId = message.conversation_id;

  const currentById = get().messages.byId;
  const currentAllIds = get().messages.allIds;
  const currentByChatId = get().messages.byConversationId[chatId];

  const newById = Object.keys(currentById).reduce<typeof currentById>((byId, key) => {
    const id = Number(key);
    if (id === localId && currentById[localId].id === undefined) {
      byId[newId] = { ...message, localId: newId };
    } else {
      byId[id] = currentById[id];
    }

    return byId;
  }, {});
  const newAllIds = currentAllIds.toSpliced(currentAllIds.indexOf(localId), 1, newId);
  const newByChatId = currentByChatId.toSpliced(currentByChatId.indexOf(localId), 1, newId);

  set(
    (state) => ({
      messages: {
        ...state.messages,
        byId: newById,
        allIds: newAllIds,
        byConversationId: {
          ...state.messages.byConversationId,
          [chatId]: newByChatId,
        },
      },
    }),
    undefined,
    'replaceLocalMessage',
  );

  get().conversations.updateChatLastReadId(chatId, message.id);
};

const emitReadDebounced = debounce((cb) => cb(), 300, false, true);

export const markMessageRead: (
  ...params: Parameters<typeof createMessagesSlice>
) => (socket: TypedSocket, messageId: number, conversationId: number) => void =
  (set) => (socket, messageId, conversationId) => {
    emitReadDebounced(async () => {
      socket.emit('read-message', { id: messageId, conversationId });
      set((state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [conversationId]: {
              ...state.conversations.byId[conversationId],
              resource: {
                ...state.conversations.byId[conversationId].resource!,
                cursor: messageId,
              },
            },
          },
        },
      }));
    });
  };
