import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons';
import useAuth from '@hooks/use-auth';
import useLastCallback from '@hooks/use-last-callback';
import { selectInputMessageReplyInfo } from '@store/selectors/messages';
import buildClassName from '@utils/build-class-name';
import { debounce } from '@utils/schedulers';
import { Badge, Button } from 'antd';
import type { FC } from 'react';
import { memo, useRef } from 'react';

import { useActiveConversation, useSharedActions } from '@/newstore/selectors/shared';

import styles from './ScrollDownButton.module.scss';

interface OwnProps {
  isShown: boolean;
}

interface StateProps {
  unreadCount?: number;
  conversationId: number;
  composerHasEmbeddedMessage: boolean;
  isLoadingMessages: boolean;
}

const scrollDebounced = debounce((cb) => cb(), 200, false);

const NonMemoScrollDownButton: FC<OwnProps & StateProps> = ({
  isShown,
  conversationId,
  unreadCount,
  composerHasEmbeddedMessage,
  isLoadingMessages,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const auth = useAuth();
  const { loadUnreadMessages } = useSharedActions();

  const handleClick = useLastCallback(async () => {
    if (!isShown) {
      return;
    }

    await loadUnreadMessages(auth, conversationId);

    // TODO: This may be prone to problems, ideally we need to detect container height changes in MessageList and keep scroll at bottom
    scrollDebounced(() => {
      const messagesContainer = elementRef.current!.parentElement!.querySelector<HTMLDivElement>('.MessageList')!;
      const messageElements = messagesContainer.querySelectorAll<HTMLDivElement>('.message-list-item');
      const lastMessageElement = messageElements[messageElements.length - 1];
      if (!lastMessageElement) {
        return;
      }

      const { offsetTop: elementTop, offsetHeight: elementHeight } = lastMessageElement;
      const { offsetHeight: containerHeight } = messagesContainer;

      messagesContainer.scrollTop = elementTop + elementHeight + 20 - containerHeight;
    });
  });

  const className = buildClassName(
    styles.root,
    isShown ? styles.revealed : styles.hidden,
    composerHasEmbeddedMessage && styles.extraHigh,
  );

  return (
    <div ref={elementRef} className={className}>
      <Badge className={styles.badge} count={unreadCount}>
        <Button
          type="primary"
          shape="circle"
          size="large"
          icon={isLoadingMessages ? <LoadingOutlined /> : <ArrowDownOutlined />}
          onClick={handleClick}
        />
      </Badge>
    </div>
  );
};

const ScrollDownButton = memo(function ScrollDownButton({ isShown }: OwnProps) {
  const { conversation, loadingMessages } = useActiveConversation();
  // TODO: move/refactor to the new store
  const composerHasEmbeddedMessage = !!selectInputMessageReplyInfo();

  return conversation ? (
    <NonMemoScrollDownButton
      isShown={isShown}
      conversationId={conversation.id}
      isLoadingMessages={loadingMessages}
      unreadCount={conversation.unread || 0}
      composerHasEmbeddedMessage={composerHasEmbeddedMessage}
    />
  ) : null;
});

export default ScrollDownButton;
