import { getAttachmentType } from '@helpers/build-attachment';
import { getFileExtension } from '@helpers/document-info';
import type { Attachment } from '@interfaces/attachment';
import File from '@pages/components/File';
import type { FC } from 'react';
import { useMemo } from 'react';

import styles from './AttachmentItem.module.scss';

interface OwnProps {
  attachment: Attachment;
  index: number;
  onDelete?: (index: number) => void;
}

const AttachmentItem: FC<OwnProps> = ({ attachment, index, onDelete }) => {
  const type = getAttachmentType(attachment);
  const content = useMemo(() => {
    // TODO: content based on type with previews and delete button
    return (
      <>
        <File
          className={styles.file}
          name={attachment.filename}
          extension={getFileExtension(attachment.filename, attachment.mimeType)}
          previewData={attachment.previewBlobUrl}
          size={attachment.size}
          small
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment, type, index, onDelete]);

  return <div className={styles.root}>{content}</div>;
};

export default AttachmentItem;
