import { LockOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import type { User } from '@context/auth.context';
import calculatePasswordStrength from '@helpers/calculate-password-strength';
import useAuth from '@hooks/use-auth';
import { useForm } from '@pages/Management/hooks/use-editable-form';
import type { InputRef } from 'antd';
import { Alert, Button, Form, Input, message, Typography } from 'antd';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ACTIONS } from './action';
import styles from './Login.module.scss';

interface LoginFormValues extends Record<string, unknown> {
  username: string;
  password: string;
}

const Login: FC = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const { isAuthenticated } = useAuth();

  const {
    form,
    isLoadingOrSubmitting,
    submitForm,
    error: formError,
  } = useForm<User, LoginFormValues>('login-form', true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (formError === 'Unauthorized') {
      setError('Username or password is incorrect.');
    } else {
      setError(formError);
    }
  }, [formError]);

  const usernameInputRef = useRef<InputRef>(null);
  const usernameInput = usernameInputRef.current;

  const onSubmit = () => {
    // noinspection JSIgnoredPromiseFromCall
    submitForm(ACTIONS.Login, 'POST');
  };

  const redirectToHome = useCallback(
    () => navigate(locationState?.returnUrl?.pathname || '/', { replace: true }),
    [navigate, locationState],
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const currentPassword = form.getFieldValue('password') as string;
    const passwordStrength = currentPassword ? calculatePasswordStrength(currentPassword) : null;
    if (passwordStrength?.id !== 3) {
      messageApi
        .warning({
          content: 'Please change your password to comply with our password policy. You will be redirected shortly.',
          duration: 5,
        })
        .then(redirectToHome);
    } else {
      redirectToHome();
    }
  }, [isAuthenticated, form, messageApi, redirectToHome]);

  useEffect(() => {
    usernameInputRef.current?.focus();
  }, [usernameInput]);

  return (
    <Form className={styles.loginForm} form={form} onFinish={onSubmit} layout="vertical">
      <div className={styles.header}>
        <MessageOutlined className={styles.appIcon} />
        <Typography.Title level={2}>Sign In</Typography.Title>
      </div>
      {contextHolder}
      {error && <Alert className={styles.formError} message={error} type="error" />}
      <Form.Item name="username" rules={[{ required: true, message: 'Please enter your username.' }]}>
        <Input
          ref={usernameInputRef}
          prefix={<UserOutlined />}
          placeholder="Username"
          autoComplete="username"
          disabled={isLoadingOrSubmitting}
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password.' }]}>
        <Input.Password
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          disabled={isLoadingOrSubmitting}
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            block={true}
            disabled={
              isLoadingOrSubmitting ||
              !form.isFieldsTouched(true) ||
              form.getFieldsError().some(({ errors }) => errors.length > 0)
            }
            loading={isLoadingOrSubmitting}
          >
            Log in
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default Login;
