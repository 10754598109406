import buildAttachment from '@helpers/build-attachment';
import useLastCallback from '@hooks/use-last-callback';
import type { Attachment } from '@interfaces/attachment';

const MEMO_EMPTY_ARRAY: Attachment[] = [];

export default function useAttachments(attachments: Attachment[], setAttachments: (attachments: Attachment[]) => void) {
  const handleClearAttachments = useLastCallback(() => {
    setAttachments(MEMO_EMPTY_ARRAY);
  });

  const handleSetAttachments = useLastCallback((newValue: Attachment[] | ((current: Attachment[]) => Attachment[])) => {
    const newAttachments = typeof newValue === 'function' ? newValue(attachments) : newValue;
    if (!newAttachments.length) {
      handleClearAttachments();
      return;
    }

    setAttachments(newAttachments);
  });

  const handleFileSelect = useLastCallback(async (files: File[]) => {
    const newAttachments = await Promise.all(files.map((file) => buildAttachment(file.name, file)));
    handleSetAttachments(newAttachments);
  });

  return {
    handleFileSelect,
    handleClearAttachments,
    handleSetAttachments,
  };
}
