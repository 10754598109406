import useAuth from '@hooks/use-auth';
import useMessageObservers from '@hooks/use-message-observers';
import useScroll from '@hooks/use-scroll';
import ServiceMessage from '@pages/ChatFeed/RightColumn/MessageList/MessageListContent/ServiceMessage';
import { formatHumanDate } from '@utils/date-format';
import type { FC, RefObject } from 'react';
import { memo, useEffect, useRef } from 'react';

import type { MessageDateGroup } from '../helpers/group-messages';
import Message from './Message';
import styles from './MessageListContent.module.scss';

interface OwnProps {
  conversationId: number;
  messageIds: number[];
  messageGroups: MessageDateGroup[];
  containerRef: RefObject<HTMLDivElement>;
  memoLastReadIdRef: { current?: number | null };
  memoUnreadDividerAfterIdRef: { current?: number | null };
  hasUnread: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFabToggle: (...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNotchToggle: (...args: any[]) => void;
}

const NonMemoMessageListContent: FC<OwnProps> = ({
  conversationId,
  messageGroups,
  containerRef,
  memoLastReadIdRef,
  memoUnreadDividerAfterIdRef,
  hasUnread,
  onFabToggle,
  onNotchToggle,
}) => {
  const { getUserId } = useAuth();
  const { observeIntersectionForReading } = useMessageObservers(containerRef, memoLastReadIdRef, conversationId);
  const { withHistoryTriggers, backwardsTriggerRef, forwardsTriggerRef, fabTriggerRef } = useScroll(
    conversationId,
    containerRef,
    hasUnread,
    onFabToggle,
    onNotchToggle,
  );

  const unreadDivider = (
    <div className="unread-divider" key={`${conversationId}-unread-divider`}>
      <span>Unread messages</span>
    </div>
  );

  const previousMessageIdRef = useRef<number | undefined>(undefined);

  const dateGroups = messageGroups.map((dateGroup, dateGroupIndex, dateGroupsArray) => {
    const senderGroups = dateGroup.senderGroups.map((senderGroup, senderGroupIndex, senderGroupsArray) => {
      return senderGroup
        .map((message, messageIndex) => {
          const isLastInList =
            messageIndex === senderGroup.length - 1 &&
            senderGroupIndex === senderGroupsArray.length - 1 &&
            dateGroupIndex === dateGroupsArray.length - 1;
          const isFirstInList = messageIndex === 0 && senderGroupIndex === 0 && dateGroupIndex === 0;
          const position = {
            isFirstInGroup: messageIndex === 0,
            isLastInGroup: messageIndex === senderGroup.length - 1,
            isLastInList,
          };

          const messages = [
            previousMessageIdRef.current === memoUnreadDividerAfterIdRef.current &&
              !isFirstInList &&
              message.user_id !== getUserId() &&
              unreadDivider,
            message.type === 'service-message' ? (
              <ServiceMessage
                key={`${message.id || message.localId}`}
                message={message}
                observeIntersectionForBottom={observeIntersectionForReading}
              />
            ) : (
              <Message
                key={`${message.id || message.localId}`}
                message={message}
                observeIntersectionForBottom={observeIntersectionForReading}
                withSenderName={position.isFirstInGroup}
                isFirstInGroup={position.isFirstInGroup}
                isLastInGroup={position.isLastInGroup}
                isLastInList={position.isLastInList}
              />
            ),
          ].filter(Boolean);

          previousMessageIdRef.current = message.id;

          return messages;
        })
        .flat();
    });

    return (
      // TODO: remove logging `data-key`
      <div className="message-date-group" key={`${dateGroup.datetime}`} data-key={`${dateGroup.datetime}`}>
        <div className={`${styles.stickyDate} ${styles.interactive}`} key="date-header">
          <span dir="auto">{formatHumanDate(dateGroup.datetime, false, true)}</span>
        </div>
        {senderGroups.flat()}
      </div>
    );
  });

  // TODO: remove logging
  useEffect(() => {
    const uniqueGroups = new Set(messageGroups.map((dateGroup) => dateGroup.datetime));
    if (uniqueGroups.size !== messageGroups.length) {
      console.warn('Duplicate date detected');
    }
  }, [messageGroups]);

  return (
    <div className={`${styles.messagesContainer}`}>
      {withHistoryTriggers && <div ref={backwardsTriggerRef} key="backwards-trigger" className="backwards-trigger" />}
      {dateGroups.flat()}
      {withHistoryTriggers && <div ref={forwardsTriggerRef} key="forwards-trigger" className="forwards-trigger" />}
      <div ref={fabTriggerRef} key="fab-trigger" className="fab-trigger" />
    </div>
  );
};

const MessageListContent = memo(NonMemoMessageListContent);
export default MessageListContent;
