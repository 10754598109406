import usePreviousDuringAnimation from '@hooks/use-previous-during-animation';
import useSyncEffect from '@hooks/use-sync-effect';
import type { Conversation } from '@interfaces/conversation';
import buildClassName from '@utils/build-class-name';
import type { FC } from 'react';
import { memo, useRef, useState } from 'react';

import { useActiveConversation } from '@/newstore/selectors/shared';

import Composer from './Composer';
import { EDITABLE_INPUT_CSS_SELECTOR, EDITABLE_INPUT_ID } from './constants';
import MessageList from './MessageList';
import styles from './RightColumn.module.scss';
import ScrollDownButton from './ScrollDownButton';

interface StateProps {
  conversation: Conversation;
}

const NonMemoRightColumn: FC<StateProps> = ({ conversation }) => {
  // const { conversationId } = useLoaderData();
  // useEffect(() => {
  //   if (conversationId) {
  //     useBoundStore.getState().setActiveConversationId(conversationId);
  //   }
  // }, [conversationId]);

  const rightColumnRef = useRef<HTMLDivElement>(null);
  const [isFabShown, setIsFabShown] = useState<boolean>();
  const [isNotchShown, setIsNotchShown] = useState<boolean>();

  const renderingIsFabShown = usePreviousDuringAnimation(isFabShown, 0);

  const canPost = conversation.is_authorized;
  const messagingDisabled = !canPost;

  useSyncEffect(() => {
    setIsNotchShown(undefined);
  }, [conversation.id]);

  const footerClassName = buildClassName(
    styles.footer,
    !canPost && styles.noComposer,
    canPost && isNotchShown && 'with-notch',
  );

  return (
    <div ref={rightColumnRef} className={styles.columnWrapper} id="RightColumn">
      {conversation.id && (
        <>
          <div className={buildClassName('messages-layout', styles.messagesLayout)}>
            <MessageList
              conversation={conversation}
              canPost={canPost}
              onFabToggle={setIsFabShown}
              onNotchToggle={setIsNotchShown}
            />
            <div className={footerClassName}>
              {canPost && (
                <Composer
                  editableInputId={EDITABLE_INPUT_ID}
                  editableInputCssSelector={EDITABLE_INPUT_CSS_SELECTOR}
                  conversationId={conversation.id}
                  conversationLanguageCode={conversation.language_code}
                  containerRef={rightColumnRef}
                />
              )}
              {messagingDisabled && <div className={styles.messagingDisabled}>DISABLED</div>}
            </div>
            <ScrollDownButton isShown={renderingIsFabShown || false} />
          </div>
        </>
      )}
    </div>
  );
};

const RightColumn = memo(function RightColumn() {
  const { conversation, isLoading } = useActiveConversation();

  if (isLoading) {
    return 'Syncing conversations...';
  }

  return conversation ? <NonMemoRightColumn conversation={conversation} /> : null;
});
export default RightColumn;
