import type { EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

// This hack is used for websocket subscriptions. Can be removed if we come up with something working without this
// React StrictMode doesn't do the cleanup/unsubscription using usual useEffect
function createUseMountedEffect() {
  if (import.meta.env.PROD) {
    return useEffect;
  }

  return <const T extends readonly unknown[]>(effect: EffectCallback, dependencies: T) => {
    const mounted = useRef(false);

    useEffect(() => {
      if (mounted.current) {
        // the cleanup is never run on production, but is run on dev with StrictMode
        const cleanup = effect();
        return () => {
          cleanup?.();
        };
      }

      return () => {
        mounted.current = true;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
  };
}

const useMountedEffect = createUseMountedEffect();

export default useMountedEffect;
