import type { MessageHandler } from '@context/socket.context';
import useAuth from '@hooks/use-auth';
import useMountedEffect from '@hooks/use-mounted-effect';
import useSocket from '@hooks/use-socket';
import { useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '../index';
import type { MessagesSlice } from '../slices/messages';

const DEFAULT_MESSAGES_BY_ID = {};

export function useMessages(conversationId: number, subscribe = true) {
  const auth = useAuth();
  const userId = auth.getUserId();
  const socket = useSocket();

  const { loadMessages, loadNewUnreadMessages, addMessage, replaceMessage, loadUnreadMessages, replaceLocalMessage } =
    useMessageActions();

  const { areMessagesLoaded, messageIds } = useBoundStore(
    useShallow((state) => {
      const messageIds = state.messages.byConversationId?.[conversationId];
      const areMessagesLoaded = Boolean(messageIds);

      return {
        messageIds,
        areMessagesLoaded,
      };
    }),
  );

  const messagesById = useBoundStore(
    useShallow((state) =>
      areMessagesLoaded
        ? Object.fromEntries(state.messages.byConversationId[conversationId].map((id) => [id, state.messages.byId[id]]))
        : DEFAULT_MESSAGES_BY_ID,
    ),
  ) as MessagesSlice['messages']['byId'];

  const { conversationIsAuthorized, subscribed, setSubscribed } = useBoundStore(
    useShallow((state) => ({
      conversationIsAuthorized: state.conversations.byId[conversationId].resource?.is_authorized,
      subscribed: state.conversations.byId[conversationId].subscribedToMessages,
      setSubscribed: state.conversations.byId[conversationId].setSubscribedToMessages,
    })),
  );

  const onMessageHandler = useCallback<MessageHandler>(
    async (message) => {
      await loadUnreadMessages(auth, conversationId);
      if (message.localId && message.user_id === userId) {
        // Own message
        replaceLocalMessage(message);
      } else {
        if (message.id && messageIds?.includes(message.id)) {
          // TODO: which case is this?
          // Message update
          replaceMessage(message);
        } else {
          // New message
          addMessage(message);
        }
      }
    },
    [loadUnreadMessages, auth, conversationId, userId, replaceLocalMessage, messageIds, replaceMessage, addMessage],
  );

  useMountedEffect(() => {
    if (subscribe && !subscribed && conversationIsAuthorized) {
      socket.on(`conversation/${conversationId}/messages`, onMessageHandler);
      setSubscribed(true);
    }

    return () => {
      if (subscribe && subscribed && conversationIsAuthorized) {
        socket.off(`conversation/${conversationId}/messages`, onMessageHandler);
        setSubscribed(false);
      }
    };
  }, [conversationId, conversationIsAuthorized, subscribe, subscribed, socket, setSubscribed]);

  useEffect(() => {
    if (!areMessagesLoaded) {
      // noinspection JSIgnoredPromiseFromCall
      loadMessages(auth, conversationId);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      loadNewUnreadMessages(auth, conversationId);
    }
  }, [conversationId, auth, areMessagesLoaded, loadMessages, loadNewUnreadMessages]);

  return {
    messageIds,
    messagesById,
  };
}

export function useMessage(messageId?: number) {
  return useBoundStore(useShallow((state) => (messageId && state.messages.byId[messageId]) || undefined));
}

export function useMessageActions() {
  return useBoundStore(
    useShallow((state) => ({
      sendMessage: state.sendMessage,
      sendAttachments: state.sendAttachments,
      addMessage: state.messages.addMessage,
      replaceMessage: state.messages.replaceMessage,
      markMessageRead: state.messages.markMessageRead,
      loadMessages: state.loadConversationMessages,
      loadUnreadMessages: state.loadUnreadMessages,
      loadNewUnreadMessages: state.loadNewUnreadMessages,
      replaceLocalMessage: state.replaceLocalMessage,
      forwardMessage: state.forwardMessage,
    })),
  );
}
