function detectTouchEnvironment() {
  if (window.matchMedia) {
    // (any-pointer:coarse) to check for any touch device and not only main input
    if (window.matchMedia('(pointer: coarse)').matches) {
      return true;
    }
  } else if (window.PointerEvent && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
    // This will detect any touch device (touchscreen on notebook), same as (any-pointer: coarse)
    return true;
  } else if (window.TouchEvent || 'ontouchstart' in window) {
    return true;
  }

  return false;
}

enum Platform {
  iOS = 'iOS',
  macOS = 'macOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

const PLATFORMS = {
  iOS: ['iPhone', 'iPad', 'iPod'],
  macOS: ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
  Windows: ['Win32', 'Win64', 'Windows', 'WinCE'],
} as const;

function getPlatform(): Platform | undefined {
  if (typeof window === 'undefined' || !window.navigator) {
    return undefined;
  }

  const { userAgent, platform } = window.navigator;
  if (!userAgent || !platform) {
    return undefined;
  }

  if (
    (PLATFORMS.iOS as readonly string[]).includes(platform) ||
    // For new IPads with M1 chip and IPadOS platform returns "MacIntel"
    (platform === 'MacIntel' && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 2)
  ) {
    return Platform.iOS;
  }

  if ((PLATFORMS.macOS as readonly string[]).includes(platform)) {
    return Platform.macOS;
  }

  if ((PLATFORMS.Windows as readonly string[]).includes(platform)) {
    return Platform.Windows;
  }

  if (/Android/.test(userAgent)) {
    return Platform.Android;
  }

  if (/Linux/.test(platform) && !/Android/.test(userAgent)) {
    return Platform.Linux;
  }

  return undefined;
}

export const IS_TOUCH_ENV = detectTouchEnvironment();
export const PLATFORM_ENV = getPlatform();
export const IS_MAC_OS = PLATFORM_ENV === Platform.macOS;
export const IS_WINDOWS = PLATFORM_ENV === Platform.Windows;
export const IS_LINUX = PLATFORM_ENV === Platform.Linux;
export const IS_IOS = PLATFORM_ENV === Platform.iOS;
export const IS_ANDROID = PLATFORM_ENV === Platform.Android;
export const IS_MOBILE = IS_IOS || IS_ANDROID;
