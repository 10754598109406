import Icon, { LockOutlined } from '@ant-design/icons';
import ReadChats from '@assets/icons/readchats.svg?react';
import UnreadChats from '@assets/icons/unread.svg?react';
import type { AuthContextType } from '@context/auth.context';
import useAuth from '@hooks/use-auth';
import { formatPastTimeShort } from '@utils/date-format';
import type { MenuProps } from 'antd';
import { Badge, Dropdown } from 'antd';
import type { FC } from 'react';
import { memo } from 'react';

import { useConversation } from '@/newstore/selectors/conversation';
import { useConversationsActions } from '@/newstore/selectors/conversations';

import styles from './ConversationListItem.module.scss';

interface StateProps {
  title: string;
  readonly: boolean;
  text: string | null;
  unreadCount: number;
  lastMessageDate: string | null;
  auth: AuthContextType;
}

interface OwnProps {
  id: number;
  active: boolean;
}

const NonMemoConversationListItem: FC<OwnProps & StateProps> = ({
  id,
  active,
  title,
  readonly,
  text,
  unreadCount,
  lastMessageDate,
  auth,
}) => {
  const { markChatRead, markChatUnread } = useConversationsActions();

  const contextMenuItems: MenuProps['items'] = [
    unreadCount > 0
      ? { label: 'Mark as read', key: 'mark-read', icon: <Icon component={ReadChats} style={{ fontSize: '1.1rem' }} /> }
      : {
          label: 'Mark as unread',
          key: 'mark-unread',
          icon: <Icon component={UnreadChats} style={{ fontSize: '1.1rem' }} />,
        },
  ];

  const onContextMenuClick: MenuProps['onClick'] = async ({ key, domEvent }) => {
    domEvent.stopPropagation();
    switch (key) {
      case 'mark-read':
        await markChatRead(id, auth);
        break;
      case 'mark-unread':
        await markChatUnread(id, auth);
        break;
      default:
        throw new Error('Unknown context menu action.');
    }
  };

  return (
    <Dropdown menu={{ items: contextMenuItems, onClick: onContextMenuClick }} trigger={['contextMenu']}>
      <div
        className={[styles.conversationListItem, active ? styles.active : ''].join(' ')}
        role="link"
        tabIndex={0}
        title={title}
      >
        <div className={styles.conversationInfo}>
          <div className={styles.infoRow}>
            <div className={styles.title}>
              <h3>{title}</h3>
            </div>
            <div className={styles.lastMessageMeta}>{lastMessageDate && formatPastTimeShort(lastMessageDate)}</div>
          </div>
          <div className={styles.subtitle}>
            <p className={styles.lastMessage}>{text}</p>
            {unreadCount > 0 ? <Badge className={styles.badge} count={unreadCount} /> : null}
          </div>
        </div>
        <div className={styles.status}>{readonly ? <LockOutlined className={styles.lock} /> : null}</div>
      </div>
    </Dropdown>
  );
};

const ConversationListItem = memo(function ConversationList({ id, active }: OwnProps) {
  const { conversation, subscribed } = useConversation(id);
  const auth = useAuth();

  return conversation && subscribed ? (
    <NonMemoConversationListItem
      id={conversation.id}
      active={active}
      title={conversation.name}
      readonly={!conversation.is_authorized}
      text={conversation.latestMessage}
      lastMessageDate={conversation.time}
      unreadCount={conversation.unread || 0}
      auth={auth}
    />
  ) : null;
});

export default ConversationListItem;
