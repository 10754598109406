import type { AuthContextType, User } from '@context/auth.context';
import { actionRequest, isActionResponse } from '@services/action-request';
import type { ActionFunction } from 'react-router-dom';

export enum ACTIONS {
  Login = 'login',
}

export const action = (auth: AuthContextType) =>
  (async ({ request }) => {
    const { intent, ...data } = await request.json();

    switch (intent) {
      case ACTIONS.Login: {
        const response = await actionRequest<User>(auth, request, 'auth/login', data);
        if (!isActionResponse(response)) {
          auth.signIn(response);
        }

        return response;
      }
      default:
        throw new Error(`Undefined intent '${intent}' for the action`);
    }
  }) satisfies ActionFunction;
