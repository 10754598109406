import { preloadImage } from '@utils/file';

export async function getAverageColor(url: string): Promise<[number, number, number]> {
  // Only visit every 5 pixels
  const blockSize = 5;
  const defaultRGB: [number, number, number] = [0, 0, 0];
  let data;
  let i = -4;
  const rgb: [number, number, number] = [0, 0, 0];
  let count = 0;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext?.('2d');
  if (!context) {
    return defaultRGB;
  }

  const image = await preloadImage(url);
  const height = image.naturalHeight || image.offsetHeight || image.height;
  const width = image.naturalWidth || image.offsetWidth || image.width;
  canvas.height = height;
  canvas.width = width;

  context.drawImage(image, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    return defaultRGB;
  }

  const length = data.data.length;

  while ((i += blockSize * 4) < length) {
    if (data.data[i + 3] === 0) continue; // Ignore fully transparent pixels
    ++count;
    rgb[0] += data.data[i];
    rgb[1] += data.data[i + 1];
    rgb[2] += data.data[i + 2];
  }

  rgb[0] = Math.floor(rgb[0] / count);
  rgb[1] = Math.floor(rgb[1] / count);
  rgb[2] = Math.floor(rgb[2] / count);

  return rgb;
}

export function getColorLuma(rgbColor: [number, number, number]) {
  const [r, g, b] = rgbColor;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
