import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '../index';
import type { ConversationItem } from '../slices/conversation';

export function useConversations() {
  const byId = useBoundStore(useShallow((state) => state.conversations.byId));
  const allIds = useBoundStore(useShallow((state) => state.conversations.allIds));
  const mappedById = useMemo(
    () => Object.fromEntries(Object.entries(byId).map(([id, item]) => [Number(id), item.resource])),
    [byId],
  ) as Record<number, (typeof byId)[number]['resource']>;
  const mapped = useMemo(
    () => allIds.filter((id) => mappedById[id] !== null).map((id) => mappedById[id]) as ConversationItem[],
    [allIds, mappedById],
  );

  return {
    conversationsById: mappedById,
    conversations: mapped,
  };
}

export function useTotalUnread() {
  const { conversations } = useConversations();

  return useMemo(
    () => conversations.reduce((acc, conversation) => acc + (conversation.unread || 0), 0),
    [conversations],
  );
}

export function useConversationsActions() {
  return useBoundStore(
    useShallow((state) => ({
      markChatRead: state.conversations.markChatRead,
      markChatUnread: state.conversations.markChatUnread,
    })),
  );
}
