import useLastCallback from '@hooks/use-last-callback';
import type { Attachment } from '@interfaces/attachment';
import MessageInput from '@pages/ChatFeed/RightColumn/Composer/MessageInput';
import { EDITABLE_INPUT_MODAL_ID } from '@pages/ChatFeed/RightColumn/constants';
import AttachmentList from '@pages/components/AttachmentList';
import { Button, Modal } from 'antd';
import type { FC } from 'react';
import { useRef, useState } from 'react';

import styles from './AttachmentModal.module.scss';

interface OwnProps {
  conversationId: number;
  attachments: Attachment[];
  getHtml: () => string;
  onCaptionUpdate: (html: string) => void;
  onSend: () => void;
  onClear: () => void;
}

const ATTACHMENT_MODAL_INPUT_ID = 'caption-input-text';

const AttachmentModal: FC<OwnProps> = ({ conversationId, attachments, getHtml, onCaptionUpdate, onSend, onClear }) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  const isOpen = Boolean(attachments.length);
  const onError = useLastCallback((error?: string) => {
    setHasError(error !== undefined);
  });

  return (
    <Modal
      title="Send File"
      centered
      maskClosable={false}
      open={isOpen}
      onCancel={onClear}
      footer={
        <div className={styles.caption}>
          <MessageInput
            ref={inputRef}
            id={ATTACHMENT_MODAL_INPUT_ID}
            editableInputId={EDITABLE_INPUT_MODAL_ID}
            isActive={isOpen}
            conversationId={conversationId}
            getHtml={getHtml}
            onUpdate={onCaptionUpdate}
            onSend={onSend}
            placeholder="Add a caption..."
            canAutoFocus={Boolean(attachments.length)}
          />
          <div className={styles.sendWrapper}>
            <Button className={styles.send} type="primary" onClick={onSend} disabled={hasError}>
              Send
            </Button>
          </div>
        </div>
      }
    >
      <AttachmentList attachments={attachments} onError={onError} />
    </Modal>
  );
};

export default AttachmentModal;
