import useAuth from '@hooks/use-auth';
import useMountedEffect from '@hooks/use-mounted-effect';
import type { Conversation, ConversationStatus } from '@interfaces/conversation';
import type { LocalMessage, Message } from '@interfaces/message';
import type { ReactNode } from 'react';
import { createContext, useCallback, useRef } from 'react';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';

export type MessageHandler = (message: Message & { localId?: number }) => void;
export type ConversationHandler = (conversation: ConversationStatus | Conversation) => void;
interface WSError {
  status: string;
  message: string;
}
export type ErrorHandler = (error: WSError) => void;
type MessageEmitter = ({ conversationId, message }: { conversationId: number; message: LocalMessage }) => void;
type MessageReceiptEmitter = ({ id, conversationId }: { id: number; conversationId: number }) => void;
type MessageDeletedEmitter = ({ id }: { id: number }) => void;
export interface AttachmentPayload {
  localId: number;
  filename: string;
  type: string;
  size: number;
  file: File;
  caption?: string;
  reply_to_id?: number;
}
type AttachmentEmitter = ({
  conversationId,
  attachments,
  caption,
}: {
  conversationId: number;
  attachments: AttachmentPayload[];
  caption?: string;
}) => void;

interface ServerToClientEvents {
  [conversationMessages: `conversation/${number}/messages`]: MessageHandler;
  [conversation: `conversation/${number}`]: ConversationHandler;
  invalidToken: () => void;
  exception: ErrorHandler;
}

interface ClientToServerEvents {
  message: MessageEmitter;
  attachments: AttachmentEmitter;
  'read-message': MessageReceiptEmitter;
  'delete-message': MessageDeletedEmitter;
}

export type TypedSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export const SocketContext = createContext<(() => TypedSocket) | undefined>(undefined);
export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { getToken, signOut } = useAuth();
  const socketRef = useRef<TypedSocket | undefined>(undefined);
  const onConnected = useCallback(() => console.log('WebSocket connection initialized.'), []);
  const onDisconnected = useCallback(
    (reason: Socket.DisconnectReason) => console.log(`WebSocket disconnected (${reason}).`),
    [],
  );
  const onInvalidToken = useCallback(() => signOut(), [signOut]);

  const getSocket = useCallback(() => {
    let initial = false;
    if (socketRef.current === undefined) {
      initial = true;
    }
    socketRef.current ??= io(import.meta.env.VITE_API_URL, {
      auth: {
        token: getToken(),
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
    });

    if (initial) {
      socketRef.current.on('connect', onConnected);
      socketRef.current.on('disconnect', onDisconnected);
      socketRef.current.on('invalidToken', onInvalidToken);
      socketRef.current.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }

    return socketRef.current;
  }, [getToken, onConnected, onDisconnected, onInvalidToken]);

  useMountedEffect(() => {
    return () => {
      if (socketRef.current !== undefined) {
        socketRef.current?.disconnect();
        socketRef.current?.off('connect', onConnected);
        socketRef.current?.off('disconnect', onDisconnected);
        socketRef.current = undefined;
      }
    };
  }, []);

  return <SocketContext.Provider value={getSocket}>{children}</SocketContext.Provider>;
};
