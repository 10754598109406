import type { ConversationHandler } from '@context/socket.context';

export interface Conversation {
  id: number;
  name: string;
  is_authorized: boolean;
  language_code: string;
  latestMessage: string;
  time: string | null;
  cursor: number;
  firstUnreadId: number | null;
  unread: number | null;
}

export type ConversationStatus = Pick<Conversation, 'latestMessage' | 'time'> & { id: never; messageId: number };

export const isConversationStatus = (
  conversation: Parameters<ConversationHandler>[0],
): conversation is ConversationStatus => !('id' in conversation);

export const conversationsComparer = (c1: Conversation, c2: Conversation) => {
  if (c1.time === null) {
    return 1;
  }

  if (c2.time === null) {
    return -1;
  }

  return c1.time > c2.time ? -1 : c1.time < c2.time ? 1 : 0;
};
