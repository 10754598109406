import useLastCallback from '@hooks/use-last-callback';
import buildClassName from '@utils/build-class-name';
import type { FC, ReactNode } from 'react';

import styles from './PickerItem.module.scss';

interface OwnProps {
  title: ReactNode;
  subtitle?: ReactNode;
  disabled?: boolean;
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  onClick?: () => void;
}

const PickerItem: FC<OwnProps> = ({
  title,
  subtitle,
  disabled,
  className,
  titleClassName,
  subtitleClassName,
  onClick,
}) => {
  const isClickable = !disabled;
  const hasSubtitle = subtitle !== undefined;

  const handleClick = useLastCallback(() => {
    if (disabled) return;

    onClick?.();
  });

  return (
    // eslint-disable-next-line
    <div
      className={buildClassName(
        styles.root,
        hasSubtitle && styles.multiline,
        disabled && styles.disabled,
        isClickable && styles.clickable,
        className,
      )}
      onClick={handleClick}
      role={isClickable ? 'button' : undefined}
      tabIndex={isClickable ? 0 : undefined}
    >
      <div className={buildClassName(styles.title, titleClassName)}>{title}</div>
      {subtitle && <div className={buildClassName(styles.subtitle, subtitleClassName)}>{subtitle}</div>}
    </div>
  );
};

export default PickerItem;
