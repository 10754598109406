import { conversationsComparer } from '@interfaces/conversation';

import {
  markChatRead,
  markChatUnread,
  updateChatCursors,
  updateChatLastReadId,
  updateChatNextCursor,
  updateChatPreviousCursor,
} from '../actions/conversations';
import type { Action, AllSlices, SliceCreator } from '../index';
import type { ConversationItem, ConversationSlice } from './conversation';
import { initConversationSlice } from './conversation';

interface ResourceState {
  byId: Record<number, ConversationSlice>;
  allIds: number[];
  isLoading: boolean;
}

const initialValues: ResourceState = {
  byId: {},
  allIds: [],
  isLoading: true,
};

interface ResourceActions {
  initConversations: (conversations: ConversationItem[]) => void;
  updateChatLastReadId: ReturnType<typeof updateChatLastReadId>;
  updateChatNextCursor: ReturnType<typeof updateChatNextCursor>;
  updateChatPreviousCursor: ReturnType<typeof updateChatPreviousCursor>;
  updateChatCursors: ReturnType<typeof updateChatCursors>;
  markChatRead: ReturnType<typeof markChatRead>;
  markChatUnread: ReturnType<typeof markChatUnread>;
}

export interface ConversationsSlice {
  conversations: ResourceState & ResourceActions;
}

export const createConversationsSlice: SliceCreator<AllSlices, ConversationsSlice> = (set, get, store) => ({
  conversations: {
    ...initialValues,
    initConversations: (conversations) => {
      const sortedConversations = conversations.sort(conversationsComparer);

      set(
        (state) => ({
          conversations: {
            ...state.conversations,
            byId: Object.fromEntries(
              sortedConversations.map((item) => [
                item.id,
                {
                  ...initConversationSlice(item.id)(set, get, store),
                  resource: {
                    ...(state.conversations.byId[item.id]?.resource ?? {}), // Temporary workaround, init is called after loading messages
                    ...item,
                  },
                },
              ]),
            ),
            allIds: sortedConversations.map((item) => item.id),
            isLoading: false,
          },
        }),
        undefined,
        'initConversations',
      );
    },
    updateChatLastReadId: updateChatLastReadId(set, get, store),
    updateChatNextCursor: updateChatNextCursor(set, get, store),
    updateChatPreviousCursor: updateChatPreviousCursor(set, get, store),
    updateChatCursors: updateChatCursors(set, get, store),
    markChatRead: markChatRead(set, get, store),
    markChatUnread: markChatUnread(set, get, store),
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ConversationsStoreAction<R extends (...args: any[]) => any> = Action<typeof createConversationsSlice, R>;
