import './App.scss';
import 'antd/dist/reset.css';

import AuthenticatedLayout from '@components/AuthenticatedLayout';
import ErrorBoundary from '@components/ErrorBoundary';
import { SocketProvider } from '@context/socket.context';
import useAuth from '@hooks/use-auth';
import usePrefersColorScheme from '@hooks/use-prefers-color-scheme';
import { Role } from '@interfaces/role';
import ChatFeed from '@pages/ChatFeed';
import { loader as conversationsListLoader } from '@pages/ChatFeed/LeftColumn/ConversationList';
import RightColumn, { loader as paramsLoader } from '@pages/ChatFeed/RightColumn';
import Login, { action as loginAction } from '@pages/Login';
import Management from '@pages/Management';
import ConversationManagement, {
  action as conversationsActions,
  loader as conversationsLoader,
} from '@pages/Management/ConversationManagement';
import MenuItemManagement, {
  action as buttonsActions,
  loader as buttonsLoader,
} from '@pages/Management/MenuManagement';
import MessageTemplateManagement, {
  action as messageTemplatesAction,
  loader as messageTemplatesLoader,
} from '@pages/Management/MessageTemplateManagement';
import OrganizationManagement, {
  action as organizationActions,
  loader as organizationLoader,
} from '@pages/Management/OrganizationManagement';
import TelegramUserManagement, {
  action as telegramUsersAction,
  loader as telegramUsersLoader,
} from '@pages/Management/TelegramUserManagement';
import UserManagement, { action as usersActions, loader as usersLoader } from '@pages/Management/UserManagement';
import OwnMessages, { loader as ownMessageLoader } from '@pages/User/OwnMessages';
import type { ThemeConfig } from 'antd';
import { App as AntApp, ConfigProvider, theme } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { RouterProviderProps } from 'react-router-dom';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { createAuthorizedLoader } from '@/app/create-authorized-loader';

const App: FC = () => {
  const auth = useAuth();
  const { preferredColorScheme } = usePrefersColorScheme();
  // We need to inject AuthContextType to our actions and loaders as we don't
  // have access to hooks from them, See:
  // https://github.com/remix-run/react-router/discussions/9564
  // https://github.com/remix-run/react-router/pull/9975

  const routes: Parameters<typeof createBrowserRouter>[0] = useMemo(() => {
    return [
      {
        path: '/login',
        element: <Login />,
        action: loginAction(auth),
        errorElement: <ErrorBoundary />,
      },
      {
        path: '/',
        element: <AuthenticatedLayout />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            element: <Navigate to="/conversations" replace />,
          },
          {
            path: 'conversations',
            element: (
              <SocketProvider>
                <ChatFeed />
              </SocketProvider>
            ),
            loader: conversationsListLoader(auth),
            children: [
              {
                id: 'conversation',
                path: ':conversationId',
                element: <RightColumn />,
                loader: paramsLoader,
              },
            ],
          },
          {
            path: 'user',
            children: [
              {
                path: 'messages',
                element: <OwnMessages />,
                loader: ownMessageLoader(auth),
              },
            ],
          },
          {
            path: 'management',
            element: <Management />,
            children: [
              {
                index: true,
                element: <Navigate to="conversations" replace />,
              },
              {
                path: 'users',
                element: <UserManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, usersLoader),
                action: usersActions(auth),
              },
              {
                path: 'conversations',
                element: <ConversationManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, conversationsLoader),
                action: conversationsActions(auth),
              },
              {
                path: 'telegram-menu',
                element: <MenuItemManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, buttonsLoader),
                action: buttonsActions(auth),
              },
              {
                path: 'settings',
                element: <OrganizationManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, organizationLoader),
                action: organizationActions(auth),
              },
              {
                path: 'telegram-users',
                element: <TelegramUserManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, telegramUsersLoader),
                action: telegramUsersAction(auth),
              },
              {
                path: 'message-templates',
                element: <MessageTemplateManagement />,
                loader: createAuthorizedLoader([Role.Admin], auth, messageTemplatesLoader),
                action: messageTemplatesAction(auth),
              },
            ],
          },
        ],
      },
    ];
  }, [auth]);

  const DOMRouterOpts: Parameters<typeof createBrowserRouter>[1] = useMemo(
    () => ({
      future: {
        v7_normalizeFormMethod: true,
        v7_fetcherPersist: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
        v7_relativeSplatPath: true,
      },
    }),
    [],
  );

  const router = useMemo(() => createBrowserRouter(routes, DOMRouterOpts), [routes, DOMRouterOpts]);
  const routerFutures: RouterProviderProps['future'] = useMemo(
    () => ({
      v7_startTransition: true,
    }),
    [],
  );

  const themeConfig: ThemeConfig = useMemo(
    () => ({
      cssVar: true,
      hashed: false,
      algorithm: preferredColorScheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
    }),
    [preferredColorScheme],
  );

  return (
    <ConfigProvider theme={themeConfig}>
      <AntApp>
        <RouterProvider router={router} future={routerFutures} />
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
