import useLastCallback from '@hooks/use-last-callback';
import type { Conversation } from '@interfaces/conversation';
import PickerItem from '@pages/ChatFeed/components/PickerItem';
import { Modal } from 'antd';
import type { FC } from 'react';
import { memo } from 'react';

import { useBoundStore } from '@/newstore';
import { useConversations } from '@/newstore/selectors/conversations';
import { useSharedActions } from '@/newstore/selectors/shared';

interface OwnProps {
  onlyAuthorized?: boolean;
  title?: string;
}

interface StateProps {
  conversations: Conversation[];
  isOpen: boolean;
}

const NonMemoChatPicker: FC<OwnProps & StateProps> = ({ title, onlyAuthorized, conversations, isOpen }) => {
  const { exitForwardMode, setForwardChat } = useSharedActions();

  if (onlyAuthorized) {
    conversations = conversations.filter((conversation) => conversation.is_authorized);
  }

  const handleSelectRecipient = useLastCallback((recipientId: number) => {
    setForwardChat(recipientId);
  });

  return (
    <Modal open={isOpen} footer={null} closable={false} maskClosable={true} title={title} onCancel={exitForwardMode}>
      {conversations
        .sort((c1, c2) => c1.name.localeCompare(c2.name))
        .map((conversation) => (
          <PickerItem
            key={conversation.id}
            title={conversation.name}
            disabled={!conversation.is_authorized}
            onClick={() => handleSelectRecipient(conversation.id)}
          />
        ))}
    </Modal>
  );
};

const ChatPicker = memo<OwnProps>(function ChatPicker({ title, onlyAuthorized }: OwnProps) {
  const { conversations } = useConversations();
  const isForwardModalOpen = useBoundStore((state) => state.isForwardModalOpen);

  return (
    <NonMemoChatPicker
      conversations={conversations}
      isOpen={isForwardModalOpen}
      title={title}
      onlyAuthorized={onlyAuthorized}
    />
  );
});

export default ChatPicker;
