import { getAttachmentType } from '@helpers/build-attachment';
import { getFileSizeString } from '@helpers/document-info';
import usePrevious from '@hooks/use-previous';
import type { Attachment } from '@interfaces/attachment';
import { Alert } from 'antd';
import type { FC } from 'react';
import { useEffect } from 'react';

import AttachmentItem from './AttachmentItem';
import styles from './AttachmentList.module.scss';

const ATTACHMENTS_TOTAL_SIZE_LIMIT = 100 * 1024 * 1024;
const ATTACHMENT_PHOTO_SIZE_LIMIT = 10 * 1024 * 1024;
const ATTACHMENT_FILE_SIZE_LIMIT = 50 * 1024 * 1024;

interface OwnProps {
  attachments: Attachment[];
  onError?: (message?: string) => void;
}

const AttachmentList: FC<OwnProps> = ({ attachments, onError }) => {
  // TODO: ability to add additional attachments and delete individual attachments
  const prevAttachments = usePrevious(attachments);
  const renderingAttachments = attachments.length ? attachments : prevAttachments;

  const totalSizeOfAttachments = attachments.reduce((sum, attachment) => sum + attachment.size, 0);
  const individualFileExceedsSizeLimit = attachments.some((attachment) => {
    const type = getAttachmentType(attachment);
    return (
      (type === 'photo' && attachment.size >= ATTACHMENT_PHOTO_SIZE_LIMIT) ||
      attachment.size >= ATTACHMENT_FILE_SIZE_LIMIT
    );
  });
  const error =
    totalSizeOfAttachments >= ATTACHMENTS_TOTAL_SIZE_LIMIT
      ? `Attachments exceed the limit of ${getFileSizeString(ATTACHMENTS_TOTAL_SIZE_LIMIT)}`
      : individualFileExceedsSizeLimit
        ? `Attachment size limit is ${getFileSizeString(ATTACHMENT_PHOTO_SIZE_LIMIT)} for photos, and ${getFileSizeString(
            ATTACHMENT_FILE_SIZE_LIMIT,
          )} for other files.`
        : undefined;

  useEffect(() => {
    onError?.(error);
  }, [error, onError]);

  return (
    <>
      {error && <Alert style={{ margin: '1rem 0' }} message={error} type="error" />}
      {!error && (
        <div className={styles.attachments}>
          {renderingAttachments?.map((attachment, i) => (
            <AttachmentItem attachment={attachment} index={i} key={attachment.uniqueId} />
          ))}
        </div>
      )}
    </>
  );
};

export default AttachmentList;
