import type { Message } from '@interfaces/message';
import { fromDateStringToEpoch, getDayStartAt } from '@utils/date-format';

type SenderGroup = Message[];

const GROUP_INTERVAL_MILLISECONDS = 600 * 1000; // 10 minutes

export interface MessageDateGroup {
  originalDate: string;
  datetime: number;
  senderGroups: SenderGroup[];
}

export function groupMessages(messages: Message[], lastReadId?: number | null): MessageDateGroup[] {
  if (messages.length === 0) {
    return [];
  }

  const initDateGroup: MessageDateGroup = {
    originalDate: messages[0].created_at,
    datetime: getDayStartAt(messages[0].created_at),
    senderGroups: [[]],
  };

  const dateGroups: MessageDateGroup[] = [initDateGroup];

  messages.forEach((message, index) => {
    const currentDateGroup = dateGroups[dateGroups.length - 1];
    const currentSenderGroup = currentDateGroup.senderGroups[currentDateGroup.senderGroups.length - 1];

    currentSenderGroup.push(message);

    const nextMessage = messages[index + 1];

    // TODO: group album messages (grouped media)

    if (nextMessage) {
      const nextMessageDayStartsAt = getDayStartAt(nextMessage.created_at);
      if (currentDateGroup.datetime !== nextMessageDayStartsAt) {
        const newDateGroup: MessageDateGroup = {
          originalDate: nextMessage.created_at,
          datetime: nextMessageDayStartsAt,
          senderGroups: [[]],
        };
        dateGroups.push(newDateGroup);
      } else if (
        (message.id === lastReadId && !(nextMessage.id === undefined && nextMessage.localId !== undefined)) ||
        messagesHasDifferentSenders(message, nextMessage) ||
        fromDateStringToEpoch(nextMessage.created_at) - fromDateStringToEpoch(message.created_at) >
          GROUP_INTERVAL_MILLISECONDS
      ) {
        currentDateGroup.senderGroups.push([]);
      }
    }
  });

  return dateGroups;
}

function messagesHasDifferentSenders(firstMessage: Message, secondMessage: Message) {
  return (
    (firstMessage.user_id !== null && secondMessage.user_id !== firstMessage.user_id) ||
    (firstMessage.telegram_user_id !== null && secondMessage.telegram_user_id !== firstMessage.telegram_user_id)
  );
}
