import type { AuthContextType } from '@context/auth.context';
import type { AttachmentPayload, TypedSocket } from '@context/socket.context';
import { getAttachmentType, getImageDimensions, getVideoDimensions } from '@helpers/build-attachment';
import type { Attachment } from '@interfaces/attachment';
import type { LocalMessage } from '@interfaces/message';
import type { LoadDirection } from '@utils/api/fetchCursorMessages';
import fetchCursorMessages from '@utils/api/fetchCursorMessages';

import type { createSharedSlice } from '../slices/shared';

export const MESSAGE_SLICE = 60;

export const loadConversationMessages: (
  ...params: Parameters<typeof createSharedSlice>
) => (auth: AuthContextType, id: number, direction?: LoadDirection, cursor?: number) => Promise<void> =
  (_, get) =>
  async (auth, id, direction = 'both', cursor?: number) => {
    const conversation = get().conversations.byId[id].resource;
    if (conversation === null) {
      throw new Error('Trying to load non-existent conversation messages.');
    }

    if (direction === 'before' && conversation.previousCursor === undefined) {
      // We are at the beginning of the list, and the load was triggered by scroll
      return;
    }

    if (direction === 'after' && conversation.nextCursor === undefined && cursor === undefined) {
      // We are at the end of the list, and the load was triggered by scroll
      return;
    }

    if (direction === 'after' && cursor !== undefined && conversation.unread === null) {
      // There is no any new messages to load
      return;
    }

    const setIsLoadingMessages = get().conversations.byId[id].setIsLoadingMessages;

    switch (direction) {
      case 'both': {
        setIsLoadingMessages(true);
        const response = await fetchCursorMessages(auth, id, conversation.cursor, direction, MESSAGE_SLICE);
        const messages = response.data;
        if (messages.length > 0) {
          get().messages.replaceMessages(messages);
        } else {
          get().messages.replaceChatMessages(id, {});
        }
        get().conversations.updateChatCursors(id, response.meta?.previous_cursor, response.meta?.next_cursor);

        setIsLoadingMessages(false);

        break;
      }
      case 'before': {
        setIsLoadingMessages(true);
        const response = await fetchCursorMessages(auth, id, conversation.previousCursor, direction, MESSAGE_SLICE);
        const messages = response.data;

        // We don't sort here, as we assume that API returned them already sorted ((a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0)) || localCompare for ISO date strings
        get().messages.addMessages(messages);
        get().conversations.updateChatPreviousCursor(id, response.meta?.previous_cursor);

        setIsLoadingMessages(false);

        break;
      }
      case 'after': {
        setIsLoadingMessages(true);
        const response = await fetchCursorMessages(
          auth,
          id,
          cursor ?? conversation.nextCursor,
          direction,
          MESSAGE_SLICE,
        );

        const messages = response.data;

        get().messages.addMessages(messages);
        get().conversations.updateChatNextCursor(id, response.meta?.next_cursor);

        setIsLoadingMessages(false);

        break;
      }
    }
  };

export const loadAllUnreadMessages: (
  ...params: Parameters<typeof createSharedSlice>
) => (auth: AuthContextType, conversationId: number) => Promise<void> =
  (_, get, store) => async (auth, conversationId) => {
    let cursor = get().conversations.byId[conversationId].resource?.nextCursor;
    if (cursor === undefined) {
      // if we don't have cursor for next messages, exit immediately
      return;
    }
    const loadMessages = get().loadConversationMessages;

    const unsubscribe = store.subscribe(async (state) => {
      cursor = state.conversations.byId[conversationId].resource?.nextCursor;
    });

    try {
      while (cursor) {
        await loadMessages(auth, conversationId, 'after');
      }
    } finally {
      unsubscribe();
    }
  };

export const loadNewUnreadMessages: (
  ...params: Parameters<typeof createSharedSlice>
) => (auth: AuthContextType, conversationId: number) => Promise<void> = (_, get) => async (auth, conversationId) => {
  const cursor = get().conversations.byId[conversationId].resource?.cursor;
  const loadMessages = get().loadConversationMessages;

  await loadMessages(auth, conversationId, 'after', cursor);
};

export const sendMessage: (
  ...params: Parameters<typeof createSharedSlice>
) => (
  auth: AuthContextType,
  socket: TypedSocket,
  conversationId: number,
  text: string,
  userId: number,
  authorName?: string,
  replyToId?: number,
) => Promise<void> = (_, get) => async (auth, socket, conversationId, text, userId, authorName, replyToId) => {
  // Load newer messages before appending the new message
  const loadUnread = get().loadUnreadMessages;

  await loadUnread(auth, conversationId);

  const nextLocalId = Math.max(...get().messages.allIds, 0) + 1;
  const localMessage: LocalMessage = {
    localId: nextLocalId,
    type: 'text',
    user_id: userId,
    conversation_id: conversationId,
    telegram_user_id: null,
    author_name: authorName !== undefined ? authorName : null,
    author_phone: null,
    body: text,
    created_at: new Date().toISOString(),
    extra_attributes: {},
    reply_to_id: replyToId,
  };

  get().messages.addMessage(localMessage);

  socket.emit('message', {
    conversationId,
    message: localMessage,
  });
};

export const forwardMessage: (
  ...params: Parameters<typeof createSharedSlice>
) => (auth: AuthContextType, socket: TypedSocket, userId: number, authorName?: string) => Promise<void> =
  (_, get) => async (auth, socket, userId, authorName) => {
    const forwardedMessage = get().forwardedMessage;
    if (!forwardedMessage) {
      return;
    }

    const { fromChatId, messageId, toChatId } = forwardedMessage;
    const message = messageId ? get().messages.byId[messageId] : undefined;

    if (!fromChatId || !toChatId || !message) {
      return;
    }

    // Load newer messages before appending the new message
    const loadUnread = get().loadUnreadMessages;

    await loadUnread(auth, toChatId);

    const nextLocalId = Math.max(...get().messages.allIds, 0) + 1;

    const localMessage: LocalMessage = {
      localId: nextLocalId,
      type: message.type === 'service-message' ? 'text' : message.type,
      user_id: userId,
      conversation_id: toChatId,
      telegram_user_id: null,
      author_name: authorName !== undefined ? authorName : null,
      author_phone: null,
      body: message.body,
      created_at: new Date().toISOString(),
      extra_attributes: message.extra_attributes,
      forward_info: {
        fromChatId,
        fromId: (message.user_id || message.telegram_user_id)!,
        fromTGId: message.telegram_user_id ?? undefined,
        fromMessageId: message.id!,
      },
    };

    get().messages.addMessage(localMessage);

    socket.emit('message', {
      conversationId: toChatId,
      message: localMessage,
    });

    get().exitForwardMode();
  };

export const sendAttachments: (
  ...params: Parameters<typeof createSharedSlice>
) => (
  auth: AuthContextType,
  socket: TypedSocket,
  conversationId: number,
  userId: number,
  attachments: Attachment[],
  caption?: string,
  authorName?: string,
  replyToId?: number,
) => Promise<void> =
  (_, get) => async (auth, socket, conversationId, userId, attachments, caption, authorName, replyToId) => {
    // Load newer messages before appending the new message
    const loadUnread = get().loadUnreadMessages;

    await loadUnread(auth, conversationId);

    const payload: AttachmentPayload[] = [];

    for (const attachment of attachments) {
      const type = getAttachmentType(attachment);
      const nextLocalId = Math.max(...get().messages.allIds, 0) + 1;

      const extra_attributes: Record<string, string | number> = {
        file_id: attachment.blobUrl,
      };

      if (type === 'photo') {
        const dimensions = await getImageDimensions(attachment.blobUrl);
        extra_attributes.width = dimensions.width;
        extra_attributes.height = dimensions.height;
      } else if (type === 'video') {
        const dimensions = await getVideoDimensions(attachment.blobUrl);
        extra_attributes.width = dimensions.width;
        extra_attributes.height = dimensions.height;
        extra_attributes.file_name = attachment.filename;
        extra_attributes.mime_type = attachment.mimeType;
        extra_attributes.file_size = attachment.size;
      } else if (type === 'document' || type === 'audio') {
        extra_attributes.file_name = attachment.filename;
        extra_attributes.mime_type = attachment.mimeType;
        extra_attributes.file_size = attachment.size;
      }

      payload.push({
        localId: nextLocalId,
        filename: attachment.filename,
        type,
        size: attachment.size,
        file: attachment.file,
        caption: caption,
        reply_to_id: replyToId,
      });

      const localMessage: LocalMessage = {
        localId: nextLocalId,
        type,
        user_id: userId,
        telegram_user_id: null,
        author_name: authorName || null,
        conversation_id: conversationId,
        author_phone: null,
        body: caption || null,
        created_at: new Date().toISOString(),
        extra_attributes: type === 'photo' ? [extra_attributes] : extra_attributes,
        reply_to_id: replyToId,
      };

      get().messages.addMessage(localMessage);
    }

    socket.emit('attachments', {
      conversationId: conversationId,
      attachments: payload,
    });
  };

export const openForwardMenu: (
  ...params: Parameters<typeof createSharedSlice>
) => (fromChatId: number, messageId: number) => void = (set) => (fromChatId, messageId) => {
  set(
    (state) => ({
      ...state,
      forwardedMessage: {
        fromChatId,
        messageId,
      },
      isForwardModalOpen: true,
    }),
    undefined,
    'openForwardMenu',
  );
};

export const exitForwardMode: (...params: Parameters<typeof createSharedSlice>) => () => void = (set) => () => {
  set(
    (state) => ({
      ...state,
      forwardedMessage: undefined,
      isForwardModalOpen: false,
    }),
    undefined,
    'exitForwardMode',
  );
};

export const setForwardChat: (...params: Parameters<typeof createSharedSlice>) => (chatId: number) => void =
  (set, get) => (chatId) => {
    set(
      (state) => {
        if (!state.forwardedMessage) {
          return state;
        }

        return {
          ...state,
          forwardedMessage: {
            ...state.forwardedMessage,
            toChatId: chatId,
          },
          isForwardModalOpen: false,
        };
      },
      undefined,
      'setForwardChat',
    );

    get().setActiveConversationId(chatId);
  };
