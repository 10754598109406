import type { ApiUser } from '@interfaces/api-user';

import type { AllSlices, SliceCreator } from '../index';

interface ResourceState {
  byUserId: Record<number, ApiUser>;
  byTelegramUserId: Record<number, ApiUser>;
  allUserIds: number[];
  allTelegramUserIds: number[];
}

const initialValues: ResourceState = {
  byUserId: {},
  byTelegramUserId: {},
  allUserIds: [],
  allTelegramUserIds: [],
};

interface ResourceActions {
  initUsers: (users: ApiUser[]) => void;
}

export interface UsersSlice {
  users: ResourceState & ResourceActions;
}

export const createUsersSlice: SliceCreator<AllSlices, UsersSlice> = (set) => ({
  users: {
    ...initialValues,
    initUsers: (users) => {
      const webUsers = users.filter((user) => user.user_id !== null);
      const telegramUsers = users.filter((user) => user.telegram_user_id !== null);

      set(
        (state) => ({
          users: {
            ...state.users,
            byUserId: Object.fromEntries(webUsers.map((user) => [user.user_id!, { ...user, id: user.user_id! }])),
            allUserIds: webUsers.map((user) => user.user_id!),
            byTelegramUserId: Object.fromEntries(
              telegramUsers.map((user) => [user.telegram_user_id!, { ...user, id: user.telegram_user_id! }]),
            ),
            allTelegramUserIds: telegramUsers.map((user) => user.telegram_user_id!),
          },
        }),
        undefined,
        'initUsers',
      );
    },
  },
});
