import toFormData from '@helpers/form-data';
import useAttachments from '@hooks/use-attachments';
import useAuth from '@hooks/use-auth';
import useLastCallback from '@hooks/use-last-callback';
import type { Attachment } from '@interfaces/attachment';
import AttachmentList from '@pages/components/AttachmentList';
import { apiRequest } from '@services/fetcher';
import { validateFiles } from '@utils/file';
import { openSystemFilesDialog } from '@utils/ui/system-files-dialog';
import { Button, Form, Input, Modal } from 'antd';
import type { ParsedQs } from 'qs';
import type { FC } from 'react';
import { useCallback, useState } from 'react';

interface OwnProps {
  open: boolean;
  onSent: () => void;
  onCancel: () => void;
  filters?: ParsedQs;
  isFormDisabled?: boolean;
}

interface Values {
  message: string;
}

const BulkMessageForm: FC<OwnProps> = ({ open, onCancel, onSent, filters, isFormDisabled }) => {
  const auth = useAuth();
  const [form] = Form.useForm<Values>();
  const [isQueueing, setIsQueueing] = useState(false);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const queueMessageSending = useCallback(
    async (message: string, attachments: Attachment[], filters?: ParsedQs) => {
      const formData = toFormData({
        message: message,
        filter: filters,
      });
      for (const attachment of attachments) {
        formData.append('files', attachment.file);
      }
      await apiRequest(`${import.meta.env.VITE_API_URL}/manage/telegram-group/message`, auth, {
        method: 'post',
        body: formData,
      });
    },
    [auth],
  );

  const { handleFileSelect, handleClearAttachments } = useAttachments(attachments, setAttachments);

  const onTriggerClose = useCallback(() => {
    form.resetFields();
    handleClearAttachments();
    onCancel();
  }, [form, handleClearAttachments, onCancel]);

  const onSubmit = useCallback(async () => {
    setIsQueueing(true);
    const values = await form.validateFields();
    await queueMessageSending(values.message, attachments, filters);
    setIsQueueing(false);
    form.resetFields();
    handleClearAttachments();
    onSent();
  }, [form, queueMessageSending, filters, handleClearAttachments, onSent, attachments]);

  const onFileSelect = useLastCallback((e: Event) => {
    const { files } = e.target as HTMLInputElement;
    const validatedFiles = validateFiles(files);

    if (!validatedFiles?.length) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    handleFileSelect(validatedFiles);
  });

  const onAttachmentError = useLastCallback((error?: string) => {
    setHasError(error !== undefined);
  });

  return (
    <Modal
      title="Send bulk message"
      open={open}
      onCancel={onTriggerClose}
      okText="Send"
      onOk={() => form.submit()}
      okButtonProps={{ disabled: isFormDisabled === true }}
      confirmLoading={isQueueing}
      footer={[
        <Button key="attach" type="default" onClick={() => openSystemFilesDialog('*', onFileSelect)}>
          Attach Files
        </Button>,
        <Button key="cancel" type="default" onClick={onTriggerClose}>
          Cancel
        </Button>,
        <Button key="send" type="primary" onClick={() => form.submit()} disabled={isFormDisabled || hasError}>
          Send
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="message"
          extra="Message will be sent to filtered conversations."
          rules={[
            {
              required: true,
              message: 'Please enter a message.',
            },
            {
              max: 4096,
              message: 'Message can not exceed 4096 characters.',
            },
          ]}
        >
          <Input.TextArea showCount maxLength={4096} rows={5} />
        </Form.Item>
      </Form>
      <AttachmentList attachments={attachments} onError={onAttachmentError} />
    </Modal>
  );
};

export default BulkMessageForm;
