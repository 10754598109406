import type { ApiUser } from '@interfaces/api-user';
import type { Message } from '@interfaces/message';
import { useShallow } from 'zustand/react/shallow';

import { useBoundStore } from '../index';

export function useActiveConversation() {
  return useBoundStore(
    useShallow((state) => {
      const slice = state.activeConversationId ? state.conversations.byId[state.activeConversationId] : null;

      return {
        conversation: slice?.resource ?? null,
        subscribed: slice?.subscribed ?? false,
        loadingMessages: slice?.isLoadingMessages ?? false,
        isLoading: state.conversations.isLoading,
      };
    }),
  );
}

export function useSharedActions() {
  return useBoundStore(
    useShallow((state) => ({
      setActiveConversationId: state.setActiveConversationId,
      loadMessages: state.loadConversationMessages,
      loadUnreadMessages: state.loadUnreadMessages,
      exitForwardMode: state.exitForwardMode,
      openForwardMenu: state.openForwardMenu,
      setForwardChat: state.setForwardChat,
    })),
  );
}

export function useSender(message?: Message): ApiUser | undefined {
  return useBoundStore(
    useShallow((state) =>
      message
        ? message.user_id
          ? state.users.byUserId?.[message.user_id]
          : state.users.byTelegramUserId?.[message.telegram_user_id!]
        : undefined,
    ),
  );
}

export function useForwardedSender(message: Message): ApiUser | undefined {
  const { forward_info } = message;
  return useBoundStore(
    useShallow((state) =>
      forward_info
        ? forward_info.fromTGId
          ? state.users.byTelegramUserId?.[forward_info.fromTGId]
          : state.users.byUserId?.[forward_info.fromId]
        : undefined,
    ),
  );
}

export function useForwardedMessage(message: Message): Message | undefined {
  const { forward_info } = message;
  return useBoundStore(
    useShallow((state) => (forward_info ? state.messages.byId?.[forward_info.fromMessageId] : undefined)),
  );
}
