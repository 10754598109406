import { EnterOutlined, PhoneOutlined } from '@ant-design/icons';
import type { ObserveFn } from '@hooks/use-intersection-observer';
import { useOnIntersect } from '@hooks/use-intersection-observer';
import type { ApiMessage, Message, Message as MessageInterface } from '@interfaces/message';
import buildClassName from '@utils/build-class-name';
import { formatTime } from '@utils/date-format';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import type { FC } from 'react';
import { useMemo, useRef } from 'react';

import { useSharedActions } from '@/newstore/selectors/shared';

import styles from './ServiceMessage.module.scss';

function getMessageHtmlId(messageId: number) {
  return `message${messageId.toString().replace('.', '-')}`;
}

interface OwnProps {
  message: Message;
  observeIntersectionForBottom: ObserveFn;
}

const ServiceMessage: FC<OwnProps> = ({ message, observeIntersectionForBottom }) => {
  const { openForwardMenu } = useSharedActions();

  const messageDate = useMemo(() => new Date(message.created_at), [message.created_at]);
  const bottomMarkerRef = useRef<HTMLDivElement>(null);
  useOnIntersect(bottomMarkerRef, observeIntersectionForBottom);
  const messageId = (message.id || message.localId) as number;

  const canBeForwarded = (message: MessageInterface): message is ApiMessage & { id: number; conversation_id: number } =>
    message.id !== undefined && message.conversation_id !== undefined && !message.deleted_at;

  const containerClassName = buildClassName('message-list-item', styles.actionMessage);

  const contextMenuItems: MenuProps['items'] = [
    ...(canBeForwarded(message)
      ? [
          {
            key: 'forward',
            label: 'Forward',
            icon: <EnterOutlined style={{ transform: 'scale(-1, -1)' }} />,
          },
        ]
      : []),
  ];

  const onContextMenuClick: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'forward' && canBeForwarded(message)) {
      openForwardMenu(message.conversation_id, message.id);
    }
  };

  return (
    <div id={getMessageHtmlId(messageId)} className={containerClassName} data-message-id={messageId}>
      <div ref={bottomMarkerRef} className="bottom-marker" data-message-id={messageId} />
      {message.author_name && (
        <div className={styles.messageTitle} dir="ltr">
          Service message triggered by {message.author_name} at {formatTime(messageDate)}
          {message.author_phone && (
            <a
              className={styles.messageTitlePhone}
              href={`tel:${message.author_phone}`}
              title={`Call ${message.author_name}`}
            >
              <PhoneOutlined />
            </a>
          )}
        </div>
      )}
      <Dropdown
        trigger={['contextMenu']}
        menu={{
          items: contextMenuItems,
          onClick: onContextMenuClick,
        }}
      >
        <div className={styles.messageContent}>{message.body}</div>
      </Dropdown>
    </div>
  );
};

export default ServiceMessage;
