import type { LoaderFunction } from 'react-router-dom';

import { useBoundStore } from '@/newstore';

export const loader = (({ params }) => {
  const conversationId = Number(params.conversationId);
  if (Number.isNaN(conversationId)) {
    throw new Error('Invalid param specified.');
  }

  // return { conversationId };
  useBoundStore.getState().setActiveConversationId(conversationId);

  return null;
}) satisfies LoaderFunction;
