import useBackgroundMode from '@hooks/use-background-mode';
import { useIntersectionObserver } from '@hooks/use-intersection-observer';
import useSocket from '@hooks/use-socket';
import type { RefObject } from 'react';

import { useMessageActions } from '@/newstore/selectors/messages';

const INTERSECTION_THROTTLE_FOR_READING = 150;

function useMessageObservers(
  containerRef: RefObject<HTMLDivElement>,
  memoLastReadIdRef: { current?: number | null },
  conversationId: number,
) {
  const socket = useSocket();
  const { markMessageRead } = useMessageActions();
  const {
    observe: observeIntersectionForReading,
    freeze: freezeForReading,
    unfreeze: unfreezeForReading,
  } = useIntersectionObserver(
    {
      rootRef: containerRef,
      throttleMs: INTERSECTION_THROTTLE_FOR_READING,
    },
    (entries) => {
      let maxId = 0;

      entries.forEach((entry) => {
        const { isIntersecting, target } = entry;

        if (!isIntersecting) {
          return;
        }

        const { dataset } = target as HTMLDivElement;
        const isLocal = dataset.isLocal === 'true';
        const messageId = Number(dataset.lastMessageId || dataset.messageId);

        if (!isLocal && messageId > maxId) {
          maxId = messageId;
        }
      });

      if ((memoLastReadIdRef.current && maxId > memoLastReadIdRef.current) || memoLastReadIdRef.current === null) {
        markMessageRead(socket, maxId, conversationId);
      }
    },
  );

  useBackgroundMode(freezeForReading, unfreezeForReading);

  return { observeIntersectionForReading };
}

export default useMessageObservers;
