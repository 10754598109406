import { PhoneOutlined } from '@ant-design/icons';
import type { ErrorHandler, TypedSocket } from '@context/socket.context';
import useAuth from '@hooks/use-auth';
import useDocumentTitle from '@hooks/use-document-title';
import useSocket from '@hooks/use-socket';
import type { TelegramUser } from '@interfaces/telegram-user';
import ChatPicker from '@pages/ChatFeed/components/ChatPicker';
import LeftColumn from '@pages/ChatFeed/LeftColumn';
import { isResponse } from '@services/fetcher';
import fetchConversationTelegramUsers from '@utils/api/fetchConversationTelegramUsers';
import { loadMessageTemplates } from '@utils/api/load-message-templates';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Modal } from 'antd';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useTotalUnread } from '@/newstore/selectors/conversations';
import { useActiveConversation } from '@/newstore/selectors/shared';

import styles from './ChatFeed.module.scss';

interface StateProps {
  socket: TypedSocket;
  unread: number;
  conversationUsers: TelegramUser[];
  activeConversationTitle?: string;
}

const NonMemoChatFeed: FC<StateProps> = ({ socket, unread, conversationUsers, activeConversationTitle }) => {
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [modal, contextHolder] = Modal.useModal();

  useDocumentTitle(unread > 0 ? `(${unread})` : undefined);

  useEffect(() => {
    const errorHandler: ErrorHandler = (exception) => {
      modal.error({
        type: 'error',
        title: 'Error',
        content: exception.message,
      });
    };

    socket.on('exception', errorHandler);

    return () => {
      socket.off('exception', errorHandler);
    };
  }, [modal, socket]);

  const items: MenuProps['items'] = useMemo(
    () =>
      conversationUsers.map((user) => ({
        key: user.id,
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`tel:${user.phone_number}`}
            title={`Call ${user.full_name}`}
          >
            {user.full_name}
          </a>
        ),
      })),
    [conversationUsers],
  );

  return (
    <div className={styles.chatFeed}>
      <ChatPicker title="Forward to..." onlyAuthorized={true} />
      <div className={`${styles.sidebar}`}>
        <LeftColumn />
      </div>
      <div className={styles.mainContent}>
        {activeConversationTitle && (
          <div className={styles.chatHeader}>
            <h1 className={styles.chatTitle}>{activeConversationTitle}</h1>
            {conversationUsers.length > 1 && (
              <Dropdown menu={{ items }}>
                <Button size="small">
                  Call <PhoneOutlined />
                </Button>
              </Dropdown>
            )}
            {conversationUsers.length == 1 && (
              <Button size="small" href={`tel:${conversationUsers?.[0]?.phone_number}`}>
                <PhoneOutlined /> Call {conversationUsers?.[0]?.full_name}
              </Button>
            )}
          </div>
        )}
        {contextHolder}
        <Outlet />
      </div>
    </div>
  );
};

const ChatFeed = memo(function ChatFeed() {
  const [conversationUsers, setConversationUsers] = useState<TelegramUser[]>([]);

  const socket = useSocket();
  const auth = useAuth();
  const userId = auth.getUserId();
  const totalUnread = useTotalUnread();
  const { conversation: activeConversation } = useActiveConversation();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeConversation?.id) {
      navigate(`${activeConversation.id}`, { viewTransition: true });
    }
  }, [navigate, activeConversation?.id]);

  useEffect(() => {
    if (activeConversation?.id) {
      fetchConversationTelegramUsers(auth, activeConversation?.id).then((response) => {
        if (isResponse(response)) {
          // Do we have a case for Response object to handle?
          return;
        }

        setConversationUsers(response.filter((user) => user.phone_number !== null));
      });
    }
  }, [auth, activeConversation?.id]);

  useEffect(() => {
    if (userId === undefined) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    loadMessageTemplates(auth);
  }, [userId, auth]);

  return (
    <NonMemoChatFeed
      socket={socket}
      unread={totalUnread}
      conversationUsers={conversationUsers}
      activeConversationTitle={activeConversation?.name}
    />
  );
});

export default ChatFeed;
