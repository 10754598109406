import { FileOutlined, PictureOutlined, PlaySquareOutlined } from '@ant-design/icons';
import trimText from '@helpers/trim-text';
import type { ApiUser } from '@interfaces/api-user';
import type { ApiMessage, ApiMessageReplyInfo } from '@interfaces/message';
import buildClassName from '@utils/build-class-name';
import type { FC } from 'react';
import React, { useCallback, useRef } from 'react';

import styles from './EmbeddedMessage.module.scss';

const NBSP = '\u00A0';
const TRUNCATED_SUMMARY_LENGTH = 80;

interface OwnProps {
  className?: string;
  replyInfo?: ApiMessageReplyInfo;
  message?: ApiMessage;
  sender?: ApiUser;
  onClick: (e: React.MouseEvent) => void;
}

const EmbeddedMessage: FC<OwnProps> = ({ className, replyInfo, message, sender, onClick }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isMediaMessage = message?.type !== 'text';

  const getMediaIcon = useCallback(() => {
    switch (message?.type) {
      case 'photo':
        return <PictureOutlined />;
      case 'audio':
      case 'video':
      case 'voice':
        return <PlaySquareOutlined />;
      case 'document':
        return <FileOutlined />;
      default:
        return null;
    }
  }, [message?.type]);

  function renderTextContent() {
    if (!message?.body) {
      switch (message?.type) {
        case 'photo':
          return 'Photo';
        case 'video':
          return 'Video';
        case 'audio':
          return 'Audio';
        case 'voice':
          return 'Voice';
        case 'document':
          return 'Document';
        default:
          return NBSP;
      }
    }

    const text = replyInfo?.quoteText ?? message?.body;

    // If the message contains both the original and edited message, we only want to keep the edited message
    return trimText(
      text?.replace(/[\s\S]+The\sedited\s(?:\(new\)\s)?(?:message|caption)\sis\s"(.+)".?/, '$1'),
      TRUNCATED_SUMMARY_LENGTH,
    );
  }

  function renderSender() {
    if (!sender?.full_name) {
      return NBSP;
    }

    return (
      <>
        <span className="embedded-sender">{sender.full_name}</span>
      </>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
    <div
      ref={ref}
      className={buildClassName(
        styles.embeddedMessage,
        className,
        isMediaMessage && styles.withThumb,
        'embedded-reply-message',
      )}
      onClick={onClick}
      role="link"
    >
      {isMediaMessage && <div className={styles.embeddedThumb}>{getMediaIcon()}</div>}
      <div className={styles.messageText}>
        <p className={styles.embeddedTextWrapper}>{renderTextContent()}</p>
        <div className={styles.messageTitle}>{renderSender()}</div>
      </div>
    </div>
  );
};

export default EmbeddedMessage;
