import type { Conversation } from '@interfaces/conversation';
import { conversationsComparer } from '@interfaces/conversation';

import type { createConversationsSlice } from './conversations';

export interface ConversationItem extends Conversation {
  previousCursor?: number;
  nextCursor?: number;
  lastReadId?: number;
}

interface ResourceState {
  resource: ConversationItem | null;
  subscribed: boolean;
  subscribedToMessages: boolean;
  isLoadingMessages: boolean;
}

const initialValues: ResourceState = {
  resource: null,
  subscribed: false,
  subscribedToMessages: false,
  isLoadingMessages: false,
};

interface ResourceActions {
  setSubscribed: (subscribed: boolean) => void;
  setSubscribedToMessages: (subscribed: boolean) => void;
  setIsLoadingMessages: (isLoadingMessages: boolean) => void;
  patchConversation: (
    value: Partial<ConversationItem> | ((previous: ConversationItem) => Partial<ConversationItem>),
  ) => void;
}

export type ConversationSlice = ResourceState & ResourceActions;

export const initConversationSlice: (
  id: number,
) => (...params: Parameters<typeof createConversationsSlice>) => ConversationSlice = (id) => (set) => ({
  ...initialValues,
  setSubscribed: (subscribed) =>
    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [id]: {
              ...state.conversations.byId[id],
              subscribed: subscribed,
            },
          },
        },
      }),
      undefined,
      'subscribeToChat',
    ),
  setSubscribedToMessages: (subscribed) =>
    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [id]: {
              ...state.conversations.byId[id],
              subscribedToMessages: subscribed,
            },
          },
        },
      }),
      undefined,
      'subscribeToChatMessages',
    ),
  setIsLoadingMessages: (loading) =>
    set(
      (state) => ({
        conversations: {
          ...state.conversations,
          byId: {
            ...state.conversations.byId,
            [id]: {
              ...state.conversations.byId[id],
              isLoadingMessages: loading,
            },
          },
        },
      }),
      undefined,
      'setIsLoadingMessages',
    ),
  patchConversation: (value) => {
    set(
      (state) => {
        const original = state.conversations.byId[id].resource;
        if (original === null) {
          console.error('Attempted to patch a non-existent conversation:', { id, value });
          return state;
        }

        value = typeof value === 'function' ? value(original) : value;

        if (Object.keys(value).length === 0) {
          return state;
        }

        const patched = { ...original, ...value };

        // TODO: check the logic, why do we have it in the first place?
        // if (patched.firstUnreadId !== original.firstUnreadId && patched.nextCursor === undefined) {
        //   patched.nextCursor =
        //     original.lastReadId && patched.firstUnreadId && original.lastReadId < patched.firstUnreadId
        //       ? patched.firstUnreadId
        //       : patched.nextCursor;
        // }

        const lastUpdateChanged = original.time !== patched.time;
        const patchedDictionary: Record<number, ConversationSlice> = {
          ...state.conversations.byId,
          [id]: { ...state.conversations.byId[id], resource: patched },
        };

        return {
          conversations: {
            ...state.conversations,
            byId: patchedDictionary,
            allIds: lastUpdateChanged
              ? Object.values(patchedDictionary)
                  .map((item) => item.resource!)
                  .sort(conversationsComparer)
                  .map((item) => item.id)
              : state.conversations.allIds,
          },
        };
      },
      undefined,
      'patchConversation',
    );
  },
});
